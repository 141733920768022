import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "../../components/Payment/Invoice.scss";
import "../header/Header.scss";
import { useParams } from "react-router-dom";
import { getInvoiceByID } from "../../services/services";
import Header from "../header/Header";

const ViewOrder = () => {
  const [data, setData] = useState();
  const [formattedDate, setFormatedDate] = useState();
  useEffect(() => {
    console.log("hiiidfssdgfd");
  }, []);

  const { orderId } = useParams();
  console.log(orderId, "id order");
  // const orderData= aysnc () => {
  //   try{
  //       const data = await getInvoiceByID(orderId);

  //   }
  //   catch(err){
  //     throw err;
  //   }
  // }
  useEffect(() => {
    console.log("hey");
    const fetchOrderData = async () => {
      try {
        const data = await getInvoiceByID(orderId);
        console.log(data, "specific order");
        setData(data);
        const date = new Date(data.createdAt).toLocaleDateString();
        setFormatedDate(date);
        // Handle data here (if needed).
      } catch (error) {
        console.error("Error fetching order data", error);
      }
    };
    fetchOrderData();
  }, []);

  // const {
  //   orderSubTotal,
  //   orderShippingPrice,
  //   orderOfferAmount,
  //   orderTotal,
  //   orderproducts,
  //   orderTaxAmount,
  //   createdAt,
  //   user,
  //   address,
  //   vendor,
  // } = invoiceData;
  const invoiceRef = useRef(0);
  // Example with timestamp
  // Formats to MM/DD/YYYY (default locale)

  const renderAddress = (label, address) => (
    <div>
      <div className="bill-heading">
        <p>{label}</p>
      </div>
      <p>
        {address?.firstName} {address?.lastName}
      </p>
      <p>{address?.address}</p>
      <p>{address?.city?.name}</p>
      <p>{address?.state?.name}</p>
      <p>{address?.country?.name}</p>
    </div>
  );

  const handleDownloadPDF = () => {
    const invoiceElement = invoiceRef.current;
    const buttons = document.querySelectorAll(
      ".btn-download-pdf,    .btn-print"
    );
    const originalStyles = {
      overflow: invoiceElement.style.overflow,
      height: invoiceElement.style.height,
      width: invoiceElement.style.width,
    };
    buttons.forEach((button) => (button.style.display = "none"));
    invoiceElement.style.overflow = "visible";
    invoiceElement.style.height = "auto"; // Ensure full content is visible
    invoiceElement.style.width = "595px"; // A4 width in points
    invoiceElement.style.padding = "20px"; // Optional padding for better spacing

    const pdfWidth = 595.28; // A4 width in points
    const pdfHeight = 841.89; // A4 height in points

    html2canvas(invoiceElement, {
      scale: 3, // Higher resolution
      useCORS: true, // Handle cross-origin issues for images/fonts
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const imgWidth = pdfWidth; // Fit content to A4 width
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

        const doc = new jsPDF("p", "pt", "a4");
        let yOffset = 0;

        // Hide unwanted elements

        while (yOffset < imgHeight) {
          doc.addImage(imgData, "PNG", 0, -yOffset, imgWidth, imgHeight);
          yOffset += pdfHeight;

          if (yOffset < imgHeight) doc.addPage();
        }

        doc.save("invoice.pdf");

        Object.assign(invoiceElement.style, originalStyles);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);

        Object.assign(invoiceElement.style, originalStyles);
      });
    buttons.forEach((button) => (button.style.display = "block"));
  };

  const handlePrint = () => {
    const invoiceElement = invoiceRef.current;

    const elementsToHide = invoiceElement.querySelectorAll(
      ".no-print, .btn-download-pdf, .btn-print, .header"
    );
    elementsToHide.forEach((el) => (el.style.display = "none"));

    const originalStyles = {
      width: invoiceElement.style.width,
      margin: invoiceElement.style.margin,
    };
    invoiceElement.style.width = "100%";
    invoiceElement.style.margin = "0";

    // Trigger print
    window.print();

    // Revert styles and visibility after printing
    elementsToHide.forEach((el) => (el.style.display = ""));
    invoiceElement.style.width = originalStyles.width;
    invoiceElement.style.margin = originalStyles.margin;
  };

  return (
    <>
      <div className="header">
        <Header />
      </div>
      <div className="success-page-container">
        <div className="invoice">
          <div className="no-print">
            <div className="popup-content" ref={invoiceRef}>
              <div className="invoice-heading">
                <h4>Invoice</h4>
              </div>
              <div className="site-content">
                <div className="diply-felx">
                  <img src="/logo.png" alt="Vendor Logo" />
                </div>
                <div>
                  <div className="shop-details">
                    <div>Gst No: {data?.vendor?.gstNumber || "N/A"}</div>
                    <div>Shop Name: {data?.vendor?.shopName || "N/A"}</div>
                    <div>Email: {data?.vendor?.email || "N/A"}</div>
                    <div>Address: {data?.vendor?.address || "N/A"}</div>
                  </div>
                  <div className="invocie-details">
                    <p>Invoice No:{data?.orderId}</p>
                    <p>Invoice Date:{formattedDate}</p>
                  </div>
                </div>
              </div>

              <div>
                <div className="diply-felx">
                  {renderAddress("Bill To Party", data?.address)}
                  {/* {renderAddress("Shipping Address", address)} */}
                </div>
              </div>
              <div className="table-container">
                <div className="table">
                  <table>
                    <thead>
                      <tr>
                        <th>id</th>
                        <th>Title</th>
                        <th>HSN</th>
                        <th>GST(%)</th>
                        <th>₹Price</th>
                        <th>Quantity</th>
                        <th>₹SubTotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.orderproducts.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>
                            <span className="pro-name">
                              {item.product?.title}
                              {item.variant?.variantAttributes.map((attr) => (
                                <div key={attr.id}>
                                  {attr.attribute.name}:{" "}
                                  {attr.attribute_value.name}
                                </div>
                              ))}
                            </span>
                          </td>
                          <td>
                            {item.product.producttaxes[0]?.hsnmaster.code}
                          </td>
                          <td>{item.product.producttaxes[0]?.value}</td>
                          <td>{item?.salePrice}</td>
                          <td>{item.quantity}</td>
                          <td>
                            {(item?.salePrice * item.quantity).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="shipping-cost">
                <div className="cost-row">
                  <span className="label">Sub Total:</span>
                  <span className="value">
                    ₹{Number(data?.orderSubTotal).toFixed(2)}
                  </span>
                </div>
                <div className="cost-row">
                  <span className="label">Tax Amount</span>
                  <span className="value">
                    ₹{Number(data?.orderTaxAmount).toFixed(2)}
                  </span>
                </div>
                <div className="cost-row">
                  <span className="label">Discount:</span>
                  <span className="value">
                    ₹{Number(data?.orderOfferAmount).toFixed(2)}
                  </span>
                </div>
                <div className="cost-row total-row">
                  <span className="label">Total:</span>
                  <span className="value">
                    ₹{Number(data?.orderTotal).toFixed(2)}
                  </span>
                </div>
              </div>

              <div className="no-pdf no-print">
                <btton btnName="close" />
                <div class="pdf">
                  <button
                    onClick={handleDownloadPDF}
                    className="btn-download-pdf"
                  >
                    Download PDF
                  </button>
                  <button onClick={handlePrint} className="btn-print">
                    Print Invoice
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// InvoiceDetail.propTypes = {
//   invoiceData: PropTypes.shape({
//     orderSubTotal: PropTypes.number.isRequired,
//     orderShippingPrice: PropTypes.number.isRequired,
//     orderOfferAmount: PropTypes.number.isRequired,
//     orderTotal: PropTypes.number.isRequired,
//     orderproducts: PropTypes.arrayOf(
//       PropTypes.shape({
//         id: PropTypes.string.isRequired,
//         product: PropTypes.shape({
//           title: PropTypes.string,
//         }),
//         variant: PropTypes.shape({
//           variantAttributes: PropTypes.arrayOf(
//             PropTypes.shape({
//               id: PropTypes.string,
//               attribute: PropTypes.shape({
//                 name: PropTypes.string,
//               }),
//               attribute_value: PropTypes.shape({
//                 name: PropTypes.string,
//               }),
//             })
//           ),
//         }),
//         quantity: PropTypes.number,
//         salePrice: PropTypes.number,
//       })
//     ).isRequired,
//     user: PropTypes.shape({
//       firstName: PropTypes.string,
//       lastName: PropTypes.string,
//     }),
//     address: PropTypes.shape({
//       firstName: PropTypes.string,
//       lastName: PropTypes.string,
//       address: PropTypes.string,
//       city: PropTypes.shape({
//         name: PropTypes.string,
//       }),
//       state: PropTypes.shape({
//         name: PropTypes.string,
//       }),
//       country: PropTypes.shape({
//         name: PropTypes.string,
//       }),
//     }),
//     vendor: PropTypes.shape({
//       gstNumber: PropTypes.string,
//       shopName: PropTypes.string,
//       email: PropTypes.string,
//       address: PropTypes.string,
//     }),
//   }).isRequired,
//   handleClosePopup: PropTypes.func.isRequired,
// };

export default ViewOrder;
