import React, { useContext, useState, useEffect, useCallback } from "react";
import "./Checkoutcomponent.scss";
import Button from "../button/Button";
import { useNavigate } from "react-router-dom";
import "../Cart/Cart.scss";
import AddressBook from "../User/AddUser";
import Modal from "../Modal/Modal";
import { IoClose } from "react-icons/io5";
import { CartContext } from "../../Context/CartContext";
import { FaMinus, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify"; //this library use for toastmessage
import "react-toastify/dist/ReactToastify.css"; //this toast css
import {
  applyCoupon,
  fetchCategories,
  fetchCountries,
  fetchPaymentMethods,
  orderCreation,
  searchUsers,
} from "../../services/services";
import { calculateCartTotals } from "../../utils/cartUtils.js";
import { getCookie } from "../../utils/TokenUtils";
import InvoiceDetail from "../Payment/PaymentSucess.js";
import { AuthContext } from "../../Context/AuthContext.js";
import { use } from "react";
const CheckoutComponent = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { cartItems, dispatch, couponResponse } = useContext(CartContext);
  const [couponName, setCouponName] = useState("");
  const token = getCookie("accessToken");

  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [transactionId, setTransactionId] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [paymentMethodChange, setPaymentMethodChange] = useState(1);
  const [searchUser, setSearchUser] = useState("");
  // const [existingUser, setExistingUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);

  const {
    discount,
    subtotalPrice,
    subtotalWithoutTax,
    gstTotal,
    totalPrice,
    shippingPrice,
  } = calculateCartTotals(cartItems, couponResponse);
  console.log(couponResponse, "couponresponse");
  const handlePaymentMethodChange = (e) => {
    console.log("before", paymentMethodChange);
    setPaymentMethodChange(e.target.value);
    setTransactionId("");
    console.log("after", paymentMethodChange);
  };
  if (cartItems.length === 0) {
    navigate("/main-page");
  }
  const productDetails = cartItems.flatMap((item) => ({
    productId: item.id,
    variantId: item.variant ? item.variant.id : null,
    quantity: item.quantity || 1,
  }));
  useEffect(() => {
    console.log(userLoading, query, "userloading");
  }, [query]);

  const handleSearchUser = (e) => {
    setResults([]);

    setSearchUser(e.target.value);
    setQuery(e.target.value);
    setUserLoading(true);
    // if (query.length <= 0) {
    //   setUserLoading(false);
    // }
    setSelectedUser(null);
  };
  useEffect(() => {
    const PaymentMethods = async () => {
      setLoading(true);
      setError(null);
      try {
        const Payments = await fetchPaymentMethods();
        console.log(Payments, "users");
        setPaymentMethod(Payments);
      } catch (error) {
        console.error("Fetch users failed:", error);
        setError(error.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    PaymentMethods();

    return;
  }, []);

  const handleAddUserClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleUpdateQuantity = (productId, variant, newQuantity, stock) => {
    if (newQuantity > stock) {
      toast.error("The quantity exceeds available stock.");
      return;
    }
    console.log("quantity", newQuantity);
    if (newQuantity < 1) {
      toast.error("Quantity cannot be less than 1.");
      return;
    }
    dispatch({
      type: "UPDATE_QUANTITY",
      payload: {
        id: productId,
        variant,
        quantity: newQuantity,
      },
    });
  };
  const handleRemoveFromCart = (productId, variant) => {
    console.log(productId);
    dispatch({
      type: "REMOVE_FROM_CART",
      payload: { id: productId, variant },
    });
    toast.success(`Product removed successfully!`);
  };
  const fetchCouponDetails = async () => {
    try {
      console.log("hello", couponName);
      if (!couponName) {
        toast.error("Please Enter Coupon Code");
        return;
      }
      const response = await applyCoupon(couponName);

      dispatch({ type: "SET_COUPON", payload: response });
      console.log(response, "coupon"); // Dispatch coupon
      toast.success(`Applied coupon ${couponName} successfully!`);
    } catch (error) {
      if (error.response.status === 404) {
        toast.error("This coupon is invalid or expired!");
      }
      if (error.response.status === 400) {
        toast.error("This coupon is invalid or expired!");
      }
    }
  };
  const handleUserSelect = useCallback((user, addresses) => {
    console.log(user, "userrr");
    setSelectedUser(user);

    setSelectedAddress(selectedUser.addresses[0].id);
  }, []);

  const handleClickDiscount = () => {
    fetchCouponDetails();
  };
  const handleRemoveDiscount = () => {
    dispatch({ type: "CLEAR_COUPON" });
    setCouponName("");
    toast.success(`Removed coupon ${couponName} successfully!`);
  };
  useEffect(() => {
    if (query.trim() === "" || selectedUser) {
      // setResults([]);
      return;
    }
    const fetchUsers = async () => {
      setUserLoading(true);
      setError(null);
      try {
        const users = await searchUsers(query, token);
        console.log(users, "users");

        setResults(users.users);
      } catch (error) {
        console.error("Fetch users failed:", error);
        setError(error.message || "An error occurred while fetching data.");
      } finally {
        setUserLoading(false);
      }
    };

    const debounce = setTimeout(() => {
      fetchUsers();
    }, 300);

    return () => clearTimeout(debounce);
  }, [query, selectedUser]);
  const handleSubmit = async () => {
    console.log("hiii");
    if (!paymentMethodChange) {
      toast.error("Please select a payment method.");
      return;
    }

    const otherOrderDetailsPos = {
      orderPaymentVia: paymentMethodChange,
      // orderUserAddressId: selectedUser.addresses[0]
      //   ? selectedAddress.addresses[0].id
      //   : null,
      orderUserId: selectedUser.id,
      orderVia: 3,
      ...(couponResponse && { coupon: couponResponse.code }),
      ...(selectedUser.addresses[0] && {
        orderUserAddressId: selectedUser.addresses[0].id,
      }),

      ...(transactionId && { paymentId: transactionId }),
    };

    const requestData = {
      productDetails,
      otherOrderDetailsPos,
    };
    console.log(paymentMethodChange, "payment change");
    if (paymentMethodChange != 1 && !transactionId) {
      console.log(paymentMethodChange, "payment change");
      toast.error(
        "Transaction ID is required for the selected payment method.dsgdfg",
        paymentMethodChange
      );
      return;
    }
    try {
      console.log(requestData, "requestData");
      const order = await orderCreation(requestData);
      console.log("hiii order");
      const orderSubTotal = order.orderTotal;
      const InvoiceData = order;
      if (order) {
        dispatch({
          type: "CLEAR_CART",
        });
        console.log("navigated");
        navigate("/invoice-page", { state: { invoiceData: order[0] } });
        console.log("navigated");
        toast.success("Order placed successfully!"); // Toast message for success
      } else {
        throw new Error("Order creation failed.");
      }
      console.log(order, "order data");
    } catch (error) {
      console.error("Error placing order:", error.response.data.error);
      toast.error(error.response.data.error); // Toast message for failure
    }
  };

  useEffect(() => {
    const accessToken = getCookie("accessToken");
    if (accessToken) {
      // navigate("/");
    } else {
      navigate("/sign-in");
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (!selectedUser) return;

    // const fetchUserDetails = async () => {
    //   setLoading(true);
    //   setError(null);
    //   try {
    //     const response = await axios.get(
    //       `${baseURL}/users/${selectedUser.id}`,
    //       {
    //         headers: { Authorization: `Bearer ${token}` },
    //       }
    //     );

    //     onUserSelect(selectedUser, response.data.addresses);
    //     const defaultAddress = response.data.addresses.find(
    //       (address) => address.default
    //     );
    //     setUserDetails(response.data);
    //     setUserAddress(defaultAddress);
    //     console.log(defaultAddress);
    //   } catch (err) {
    //     setError("An error occurred while fetching user details.");
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    // fetchUserDetails();
  }, [selectedUser, handleUserSelect]);
  useEffect(() => {
    console.log("abcde", selectedUser);
  }, [selectedUser]);
  const handleNewUserCreated = (user) => {
    if (!user || Object.keys(user).length === 0) {
      toast.error("User creation failed or empty user data returned.");
      return;
    }

    console.log(user, "userssssss");
    // if (!user.address.id || !user.address.firstName || !user.address.email) {
    //   toast.error("User data is incomplete.");
    //   return;
    // }

    setSelectedUser(user);
  };
  console.log("selecteduser 312", selectedUser);

  return (
    <>
      <ToastContainer
        className="toast-container-center"
        position="bottom-right"
      />
      <div className="responsive-userdetails">
        <div className="customer-details">
          <p>Customer Details</p>
          <div className="search-user">
            <input
              type="search"
              value={query}
              // onChange={(e) => setQuery(e.target.value)}
              onChange={handleSearchUser}
              placeholder="Search Customer"
            />
            <button className="adduser-btn" onClick={handleAddUserClick}>
              <FaPlus /> New Customer
            </button>
            {userLoading && query && <p>Searching...</p>}
            {error && <p>{error}</p>}
            <div className="results">
              <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <AddressBook
                  onClose={handleCloseModal}
                  onUserCreated={handleNewUserCreated}
                />
              </Modal>
              {selectedUser ? (
                <div className="selected-user">
                  <h4>Customer Information</h4>
                  <p>
                    Name: {selectedUser.firstName} {selectedUser.lastName}
                  </p>
                  {selectedUser.email && <p>Email: {selectedUser.email}</p>}
                  {selectedUser.phoneNumber && (
                    <p>Phone: {selectedUser.phoneNumber}</p>
                  )}

                  <p>
                    <p> Address</p>
                    {selectedUser.addresses[0]?.address || "Address Not Found"},
                    {selectedUser.addresses[0]?.country.name},{" "}
                    {selectedUser.addresses[0]?.state.name},
                    {selectedUser.addresses[0]?.city.name}
                  </p>
                </div>
              ) : results && results.length > 0 ? (
                results.map((result) => (
                  <div key={result.id} className="user-search">
                    <div className="search-user">
                      <input
                        type="radio"
                        id={`user-${result.id}`}
                        name="selectedUser"
                        value={result.id}
                        checked={selectedUser?.id === result.id}
                        onChange={() => setSelectedUser(result)} // Set selected user
                      />
                    </div>
                    <div className="user-details">
                      <label
                        htmlFor={`user-${result.id}`}
                        className="user-info"
                      >
                        <div className="user_name">{result.firstName}</div>
                        <div className="user_email">{result.email}</div>
                        <div className="user_phone">{result.phoneNumber}</div>
                        <div className="user_address">
                          {result.addresses && result.addresses.length > 0
                            ? result.addresses[0].address
                            : "No address available"}
                        </div>
                        <div className="user_address">
                          {result.addresses && result.addresses.length > 0
                            ? result.addresses[0]?.country.name
                            : ""}
                        </div>
                        <div className="user_address">
                          {result.addresses && result.addresses.length > 0
                            ? result.addresses[0]?.state.name
                            : ""}
                        </div>
                        <div className="user_address">
                          {result.addresses && result.addresses.length > 0
                            ? result.addresses[0]?.city.name
                            : ""}
                        </div>
                      </label>
                    </div>
                  </div>
                ))
              ) : query.trim() && !userLoading ? (
                <div>No users found</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="containers">
        <div className="top-links">
          <a href="/main-page" className="mainpage-link">
            Home
          </a>
          <p>/</p>
          <a href="" className="disable-link">
            checkout
          </a>
        </div>

        <div className="checkout-container">
          <div className="checkout-left-container">
            {/* Products List Section */}
            <p>Order Summary</p>
            <div className="cart-items">
              {cartItems.map((item) => (
                <div
                  className="cart-item"
                  key={`${item.id}-${item.variant?.id || "default"}`}
                >
                  <div className="cart-item-image">
                    <img
                      src={
                        item.images.find(
                          (img) => img.id === item.variant?.imageId
                        )?.imageUrl || item.images[0].imageUrl
                      }
                      alt={item.title}
                    />
                  </div>
                  <div className="cart-item-details">
                    <div className="test">
                      <div className="cart-item-title">
                        <p>{item.title}</p>
                      </div>
                      <div className="cart-item-brand">
                        <p>Brand: {item.brand.name}</p>
                      </div>
                      <div className="cart-item-color">
                        {item.variant?.variantAttributes?.map((attr) => (
                          <p key={attr.id}>
                            {attr.attribute.name}: {attr.attribute_value.name}
                          </p>
                        ))}
                      </div>
                    </div>
                    <div className="cart-item-detail">
                      <div className="cart-item-quantity">
                        <button
                          className="decrement"
                          onClick={() =>
                            handleUpdateQuantity(
                              item.id,
                              item.variant,
                              item.quantity - 1
                            )
                          }
                          disabled={item.quantity <= 1}
                        >
                          <FaMinus />
                        </button>
                        <span className="quantity-display">
                          {item.quantity}
                        </span>
                        <button
                          className="increment"
                          onClick={() =>
                            handleUpdateQuantity(
                              item.id,
                              item.variant,
                              item.quantity + 1
                            )
                          }
                        >
                          <FaPlus />
                        </button>
                      </div>
                      <div className="cart-item-price">
                        <h3>₹{item.salePrice * item.quantity}</h3>
                      </div>
                      <div className="cart-item-remove">
                        <button
                          type="button"
                          onClick={() =>
                            handleRemoveFromCart(item.id, item.variant)
                          }
                        >
                          <IoClose />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Coupon Section */}
            <div className="coupon-section">
              <p>Promo Code / Coupon</p>
              <div className="coupon-input">
                <input
                  type="text"
                  value={couponName}
                  onChange={(e) => setCouponName(e.target.value)}
                  readOnly={!!couponResponse}
                />
                <div className="coupon-btn">
                  {couponResponse ? (
                    <button
                      className="remove-btn"
                      onClick={handleRemoveDiscount}
                    >
                      REMOVE
                    </button>
                  ) : (
                    <button onClick={handleClickDiscount}>APPLY </button>
                  )}
                </div>
              </div>
              {couponResponse && (
                <div className="coupon-applied-message">
                  <span className="success-icon">&#10003;</span>
                  <p> Coupon applied successfully!</p>
                </div>
              )}
            </div>

            {/* Payment Method Section */}
            <div className="payment-method-section">
              <p>Payment Method</p>
              <div className="payment-methods">
                {paymentMethod.map((item) => (
                  <div className="payments-input">
                    <div>
                      <label>
                        <input
                          type="radio"
                          id={item.id}
                          value={item.id}
                          checked={paymentMethodChange == item.id}
                          onChange={handlePaymentMethodChange}
                        />
                      </label>
                    </div>
                    <div>{item.name}</div>
                  </div>
                ))}
              </div>
              {paymentMethodChange != 1 && ( // Replace with your bank transfer ID
                <div className="transaction-id-container">
                  <label htmlFor="transaction-id">Transaction ID:</label>
                  <input
                    type="text"
                    id="transaction-id"
                    name="transaction-id"
                    value={transactionId}
                    onChange={(e) => setTransactionId(e.target.value)}
                    placeholder="Enter transaction ID"
                    required
                  />
                  {!transactionId && (
                    <p className="error-message">
                      Transaction ID is required for bank transfers.
                    </p>
                  )}
                </div>
              )}

              <div className="notes-section">
                <h3>Order Notes</h3>
                <textarea placeholder="Add any special notes..." />
              </div>
            </div>
            <div className="carttotal">
              <div className="amount-left">
                <div>
                  <p>
                    SubTotal:{" "}
                    <span className="amount-right">
                      ₹{subtotalPrice.toFixed(2)}
                    </span>
                  </p>
                  <p>
                    GST:{" "}
                    <span className="amount-right">₹{gstTotal.toFixed(2)}</span>
                  </p>
                  <p>
                    Discount: <span className="amount-right">₹{discount}</span>
                  </p>
                  <p>
                    Grand Total:{" "}
                    <span className="amount-right">₹{totalPrice}</span>
                  </p>
                </div>
              </div>
              <div>
                <p>
                  (Items: {cartItems.length}, Quantity:{" "}
                  {cartItems.reduce((acc, item) => acc + item.quantity, 0)})
                </p>
              </div>
              <div className="checkout-button-container">
                <Button
                  type="submit"
                  label="Proceed to Pay"
                  className={`btn-primary ${
                    cartItems.length === 0 ||
                    !selectedUser ||
                    !paymentMethodChange
                      ? "btn-disabled"
                      : ""
                  }`}
                  onClick={handleSubmit}
                  disabled={
                    cartItems.length === 0 ||
                    !selectedUser ||
                    !paymentMethodChange
                  }
                />
              </div>
            </div>
          </div>

          {/* Right Container (Customer Details) */}
          <div className="checkout-right-container">
            <div className="customer-details">
              <p>Customer Details</p>
              <div className="search-user">
                <input
                  type="search"
                  value={query}
                  // onChange={(e) => setQuery(e.target.value)}
                  onChange={handleSearchUser}
                  placeholder="Search Customer"
                />
                <button className="adduser-btn" onClick={handleAddUserClick}>
                  <FaPlus /> New Customer
                </button>
                {userLoading && query && <p>Searching...</p>}
                {error && <p>{error}</p>}
                <div className="results">
                  <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                    <AddressBook
                      onClose={handleCloseModal}
                      onUserCreated={handleNewUserCreated}
                    />
                  </Modal>
                  {selectedUser ? (
                    <div className="selected-user">
                      <h4>Customer Information</h4>
                      <p>
                        Name: {selectedUser.firstName} {selectedUser.lastName}
                      </p>
                      {selectedUser.email && <p>Email: {selectedUser.email}</p>}
                      {selectedUser.phoneNumber && (
                        <p>Phone: {selectedUser.phoneNumber}</p>
                      )}

                      <p>
                        <p> Address</p>
                        {selectedUser.addresses[0]?.address ||
                          "Address Not Found"}
                        ,{selectedUser.addresses[0]?.country.name},{" "}
                        {selectedUser.addresses[0]?.state.name},
                        {selectedUser.addresses[0]?.city.name}
                      </p>
                    </div>
                  ) : results && results.length > 0 ? (
                    results.map((result) => (
                      <div key={result.id} className="user-search">
                        <div className="search-user">
                          <input
                            type="radio"
                            id={`user-${result.id}`}
                            name="selectedUser"
                            value={result.id}
                            checked={selectedUser?.id === result.id}
                            onChange={() => setSelectedUser(result)} // Set selected user
                          />
                        </div>
                        <div className="user-details">
                          <label
                            htmlFor={`user-${result.id}`}
                            className="user-info"
                          >
                            <div className="user_name">{result.firstName}</div>
                            <div className="user_email">{result.email}</div>
                            <div className="user_phone">
                              {result.phoneNumber}
                            </div>
                            <div className="user_address">
                              {result.addresses && result.addresses.length > 0
                                ? result.addresses[0].address
                                : "No address available"}
                            </div>
                            <div className="user_address">
                              {result.addresses && result.addresses.length > 0
                                ? result.addresses[0]?.country.name
                                : ""}
                            </div>
                            <div className="user_address">
                              {result.addresses && result.addresses.length > 0
                                ? result.addresses[0]?.state.name
                                : ""}
                            </div>
                            <div className="user_address">
                              {result.addresses && result.addresses.length > 0
                                ? result.addresses[0]?.city.name
                                : ""}
                            </div>
                          </label>
                        </div>
                      </div>
                    ))
                  ) : !userLoading ? (
                    <div>No users found</div>
                  ) : null}

                  {/* {selectedUser ? (
                    <div key={selectedUser.id} className="user-search">
                      <div className="search-user">
                        <input
                          type="radio"
                          id={`user-${selectedUser.id}`}
                          name="selectedUser"
                          value={selectedUser.id}
                          checked={selectedUser?.id === selectedUser.id}
                          onChange={() => setSelectedUser(null)}
                        />
                      </div>
                      <div className="user-details">
                        <label
                          htmlFor={`user-${selectedUser.id}`}
                          className="user-info"
                        >
                          <div className="user_name">
                            {selectedUser.firstName}
                          </div>
                          <div className="user_email">{selectedUser.email}</div>
                          <div className="user_phone">
                            {selectedUser.phoneNumber}
                          </div>
                          <div className="user_address">
                            {selectedUser.addresses &&
                            selectedUser.addresses.length > 0
                              ? selectedUser.addresses[0].address
                              : "No address available"}
                          </div>
                          <div className="user_address">
                            {selectedUser.addresses &&
                            selectedUser.addresses.length > 0
                              ? selectedUser.addresses[0]?.country.name
                              : ""}
                          </div>
                          <div className="user_address">
                            {selectedUser.addresses &&
                            selectedUser.addresses.length > 0
                              ? selectedUser.addresses[0]?.state.name
                              : ""}
                          </div>
                          <div className="user_address">
                            {selectedUser.addresses &&
                            selectedUser.addresses.length > 0
                              ? selectedUser.addresses[0]?.city.name
                              : ""}
                          </div>
                        </label>
                      </div>
                    </div>
                  ) : results && results.length > 0 ? (
                    results.map((result) => (
                      <div key={result.id} className="user-search">
                        <div className="search-user">
                          <input
                            type="radio"
                            id={`user-${result.id}`}
                            name="selectedUser"
                            value={result.id}
                            checked={selectedUser?.id === result.id}
                            onChange={() => setSelectedUser(result)} // Set selected user
                          />
                        </div>
                        <div className="user-details">
                          <label
                            htmlFor={`user-${result.id}`}
                            className="user-info"
                          >
                            <div className="user_name">{result.firstName}</div>
                            <div className="user_email">{result.email}</div>
                            <div className="user_phone">
                              {result.phoneNumber}
                            </div>
                            <div className="user_address">
                              {result.addresses && result.addresses.length > 0
                                ? result.addresses[0].address
                                : "No address available"}
                            </div>
                            <div className="user_address">
                              {result.addresses && result.addresses.length > 0
                                ? result.addresses[0]?.country.name
                                : ""}
                            </div>
                            <div className="user_address">
                              {result.addresses && result.addresses.length > 0
                                ? result.addresses[0]?.state.name
                                : ""}
                            </div>
                            <div className="user_address">
                              {result.addresses && result.addresses.length > 0
                                ? result.addresses[0]?.city.name
                                : ""}
                            </div>
                          </label>
                        </div>
                      </div>
                    ))
                  ) : query.trim() && !loading ? (
                    <div>No users found</div>
                  ) : null} */}
                </div>
              </div>

              {/* <div className="user-details">
            <h3>
              <b>User Details</b>
            </h3>
            {userDetails ? (
              <>
                <p>{`${userDetails.firstName} ${userDetails.lastName}`}</p>
                <p>{userDetails.email}</p>
                {userAddress ? (
                  <div>
                    <h3>
                      <b>User Address</b>
                    </h3>
                    <p>{`${userAddress.firstName} ${userAddress.lastName}`}</p>
                    <p>{userAddress.address}</p>
                    <p>{`${userAddress.state?.name || "State not available"}, ${
                      userAddress.city?.name || "City not available"
                    }`}</p>
                    <p>{`${
                      userAddress.country?.name || "Country not available"
                    } - ${userAddress.zipCode}`}</p>
                  </div>
                ) : (
                  <div className="error-list">
                    This user has no address. Please create one.
                  </div>
                )}
              </>
            ) : (
              <div className="error-list">No user details available.</div>
            )}
            <button className="lite-btn" onClick={() => setSelectedUser(null)}>
              Change User
            </button>
          </div> */}
            </div>

            {/* Cart Total Section */}
            <div className="cart-total">
              <div className="amount-left">
                <div>
                  <p>
                    SubTotal:{" "}
                    <span className="amount-right">
                      ₹{subtotalPrice.toFixed(2)}
                    </span>
                  </p>
                  <p>
                    GST:{" "}
                    <span className="amount-right">₹{gstTotal.toFixed(2)}</span>
                  </p>
                  <p>
                    Discount:{" "}
                    <span className="amount-right">₹{discount.toFixed(2)}</span>
                  </p>
                  <p>
                    Grand Total:{" "}
                    <span className="amount-right">
                      ₹{totalPrice.toFixed(2)}
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <h3>
                  (Items: {cartItems.length}, Quantity:{" "}
                  {cartItems.reduce((acc, item) => acc + item.quantity, 0)})
                </h3>
              </div>
              <div className="checkout-button-container">
                <Button
                  type="submit"
                  label="Proceed to Pay"
                  className={`btn-primary ${
                    cartItems.length === 0 ||
                    !selectedUser ||
                    !paymentMethodChange
                      ? "btn-disabled"
                      : ""
                  }`}
                  onClick={handleSubmit}
                  disabled={
                    cartItems.length === 0 ||
                    !selectedUser ||
                    !paymentMethodChange
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutComponent;
