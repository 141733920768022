import React, { createContext, useReducer, useEffect, useContext } from "react";
import axios from "axios";
import { API_BASE_URL } from "../services/services";
import { getCookie } from "../utils/TokenUtils";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
// import { ToastContainer } from "react-toastify"; //this library use for toastmessage
// import "react-toastify/dist/ReactToastify.css"; //this toast css

const BASEURL = API_BASE_URL;
const token = getCookie("accessToken");
const userDetailsFromCookies = getCookie("userDetails")
  ? JSON.parse(getCookie("userDetails"))
  : null;
const userId = userDetailsFromCookies ? userDetailsFromCookies.id : null;

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

const initialState = {
  cartItems: JSON.parse(localStorage.getItem("cartItems")) || [],
  buyNowItems: JSON.parse(localStorage.getItem("buyNowItems")) || [],
  couponResponse: null, // To handle coupon responses
};

const updateLocalStorage = (cartItems, buyNowItems) => {
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
  localStorage.setItem("buyNowItems", JSON.stringify(buyNowItems));
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case "INIT_CART": {
      const { cartItems, buyNowItems } = action.payload;
      return { ...state, cartItems, buyNowItems };
    }
    case "ADD_TO_CART": {
      const { productDetails, variant, quantity } = action.payload;
      const availableStock = variant ? variant.stock : productDetails.stock;
      console.log(productDetails, variant, quantity);
      const existingItem = state.cartItems.find(
        (item) =>
          item.id === productDetails.id &&
          (!variant || item.variant?.id === variant.id)
      );

      console.log(existingItem, "exisitingItem");

      let newCartItems;
      // if (existingItem) {
      //   const availableStock = variant ? variant.stock : productDetails.stock;

      //   newCartItems = state.cartItems.map((item) =>
      //     item.id === productDetails.id &&
      //     (!variant || item.variant?.id === variant.id)
      //       ? {
      //           ...item,
      //           quantity: (item.quantity + quantity, availableStock),
      //         }
      //       : item
      //   );
      // }
      if (existingItem) {
        console.log(existingItem, "exist");
        const availableStock = variant ? variant.stock : productDetails.stock;
        console.log(availableStock, "stock");

        newCartItems = state.cartItems.map((item) => {
          if (
            item.id === productDetails.id &&
            (!variant || item.variant?.id === variant.id)
          ) {
            console.log("Updated Item Quantity:", item.quantity);
            // Log item quantity before update

            const updatedQuantity = item.quantity + quantity;

            if (updatedQuantity > availableStock) {
              toast.error("Stock Not Available");
              return;
            }
            item.quantity = updatedQuantity;
            toast.success("product added successfully");
            return {
              ...item,
              quantity: Math.min(item.quantity + quantity, availableStock),
            };
          }
          console.log(quantity, "item");
          return item;
        });
      } else {
        const availableStock = variant ? variant.stock : productDetails.stock;
        if (availableStock == 0) {
          toast.error("Stock Not Available");
          return { ...state };
        } else {
          newCartItems = [
            ...state.cartItems,
            {
              ...productDetails,
              variant,
              quantity: Math.min(quantity, availableStock),
              price: variant ? variant.salePrice : productDetails.salePrice,
            },
            // console.log(quantity, "quantiyyy"),
          ];
        }
        console.log(newCartItems, "aa");
        updateLocalStorage(newCartItems, state.buyNowItems);
        toast.success("product added successfully");
        return { ...state, cartItems: newCartItems };
      }
    }
    case "REMOVE_FROM_CART": {
      const { id, variant } = action.payload;
      const newCartItems = state.cartItems.filter((item) => {
        const matchesId = item.id === id;
        const matchesVariant = variant ? item.variant?.id === variant.id : true;
        return !(matchesId && matchesVariant);
      });
      updateLocalStorage(newCartItems, state.buyNowItems);
      // toast.success("Product Removed from Successfully");
      return { ...state, cartItems: newCartItems };
    }
    case "BUY_NOW": {
      const { productDetails, variant, quantity } = action.payload;
      const availableStock = variant ? variant.stock : productDetails.stock;
      const newBuyNowItems = [
        {
          ...productDetails,
          variant,
          quantity: Math.min(quantity, availableStock),
          price: variant ? variant.salePrice : productDetails.salePrice,
        },
      ];

      updateLocalStorage(state.cartItems, newBuyNowItems);
      return { ...state, buyNowItems: newBuyNowItems };
    }
    case "CLEAR_CART":
      updateLocalStorage([], state.buyNowItems); // clear cart items only
      return { ...state, cartItems: [] };

    case "CLEAR_BUY_NOW":
      updateLocalStorage(state.cartItems, []); // clear only buy now items
      return { ...state, buyNowItems: [] };

    case "UPDATE_QUANTITY": {
      const { id, variant, quantity } = action.payload;
      const newCartItems = state.cartItems.map((item) => {
        const matchesId = item.id === id;
        const matchesVariant = variant ? item.variant?.id === variant.id : true;
        if (matchesId && matchesVariant) {
          const availableStock = variant ? variant.stock : item.stock;
          const newQuantity = Math.min(quantity, availableStock);
          return { ...item, quantity: newQuantity };
        }
        return item;
      });
      updateLocalStorage(newCartItems, state.buyNowItems);
      return { ...state, cartItems: newCartItems };
    }
    case "SET_COUPON":
      return { ...state, couponResponse: action.payload };

    case "CLEAR_COUPON":
      return { ...state, couponResponse: null };

    default:
      const subtotal = state.cartItems.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
      return { ...state, subtotal };
  }
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const location = useLocation();

  // useEffect(() => {
  //   const fetchCartData = async () => {
  //     if (userId && token) {
  //       try {
  //         // Sync local storage cart items with the server
  //         const localCartItems =
  //           JSON.parse(localStorage.getItem("cartItems")) || [];
  //         if (localCartItems.length > 0) {
  //           await axios.post(
  //             `${BASEURL}/api/admin/users/${userId}/cart`,
  //             { items: localCartItems },
  //             {
  //               headers: { Authorization: `Bearer ${token}` },
  //             }
  //           );
  //         }

  //         // Fetch the cart data from the server
  //         const response = await axios.get(
  //           `${BASEURL}/api/admin/users/${userId}/cart`,
  //           {
  //             headers: { Authorization: `Bearer ${token}` },
  //           }
  //         );
  //         const cartItems = response.data.items || [];
  //         updateLocalStorage(cartItems, state.buyNowItems);
  //         dispatch({
  //           type: "INIT_CART",
  //           payload: { cartItems, buyNowItems: state.buyNowItems },
  //         });
  //       } catch (error) {
  //         console.error("Failed to fetch and sync cart data:", error);
  //       }
  //     } else {
  //       // If no userId, just load from local storage
  //       const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  //       dispatch({
  //         type: "INIT_CART",
  //         payload: { cartItems, buyNowItems: state.buyNowItems },
  //       });
  //     }
  //   };

  //   fetchCartData();
  // }, [userId, token]);

  useEffect(() => {
    updateLocalStorage(state.cartItems, state.buyNowItems);
  }, [state.cartItems, state.buyNowItems]);

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath !== "/cart" && currentPath !== "/checkout") {
      dispatch({ type: "CLEAR_COUPON" }); // Clear coupon response
      dispatch({ type: "CLEAR_BUY_NOW" }); // Clear buyNowItems
    }
  }, [location]);

  return (
    <>
      {/* <ToastContainer
        className="toast-container-center"
        position="bottom-right"
      /> */}
      <CartContext.Provider value={{ ...state, dispatch }}>
        {children}
      </CartContext.Provider>
    </>
  );
};

export { CartContext };
