// import React, { useEffect, useState } from "react";
// import { FaDownload } from "react-icons/fa";
// import { FaFilePdf } from "react-icons/fa6";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import { reportsByDate } from "../../services/services";
// import Pagination from "../../components/Pagination/Pagination";

// function ReportTable({ startDate, endDate }) {
//   const [datereports, setDatereports] = useState([]);
//   const [currentpage, setCurrentpage] = useState(1);
//   const [totalorder, setTotalorder] = useState(null);

//   const gettingReportsByDate = async () => {
//     try {
//       const data = await reportsByDate(startDate, endDate, currentpage);
//       setDatereports(data.Orders);
//       setTotalorder(data.totalOrders);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     gettingReportsByDate(startDate, endDate);
//   }, [startDate, endDate, currentpage]);

//   const downloadPDF = () => {
//     const doc = new jsPDF();
//     doc.text("Report Table", 14, 10);

//     const columns = ["S.No", "Order ID", "Order Date", "Total", "Payment Type"];
//     const rows = datereports.map((row, index) => [
//       index + 1,
//       row.orderId,
//       row.createdAt ? new Date(row.createdAt).toLocaleDateString() : "N/A",
//       row.orderTotal,
//       row.orderPaymentVia === 1
//         ? "Cash"
//         : row.orderPaymentVia === 2
//         ? "Credit"
//         : row.orderPaymentVia === 5
//         ? "UPI"
//         : "",
//     ]);

//     doc.autoTable({
//       startY: 20,
//       head: [columns],
//       body: rows,
//       theme: "striped",
//       headStyles: { fillColor: [22, 160, 133] },
//     });

//     doc.save("ReportTable.pdf");
//   };

//   return (
//     <div className="report-section">
//       <div className="pdf-download">
//         <button onClick={downloadPDF} className="download-button">
//           <img src="./pdf.png" alt="pdf-icon"></img>
//         </button>
//       </div>
//       <div className="table-container">
//         <h2>Report Table</h2>
//         <table className="report-table">
//           <thead>
//             <tr>
//               <th>S.No</th>
//               <th>OrderId</th>
//               <th>Products</th>
//               <th>Order Date</th>
//               <th>Total</th>
//               <th>Payment Type</th>
//             </tr>
//           </thead>
//           <tbody>
//             {datereports.map((row, index) => (
//               <tr key={row.id}>
//                 <td>{(currentpage - 1) * 10 + index + 1}</td>
//                 <td>{row.orderId}</td>
//                 <td>
//                   {row.orderproducts.map((products, index) => (
//                     <span key={index}>
//                       {products.product.title}
//                       <br />
//                     </span>
//                   ))}
//                 </td>

//                 <td>
//                   {row.createdAt
//                     ? new Date(row.createdAt).toLocaleDateString()
//                     : "N/A"}
//                 </td>
//                 <td>{row.orderTotal}</td>
//                 <td>
//                   {row.orderPaymentVia === 1
//                     ? "Cash"
//                     : row.orderPaymentVia === 2
//                     ? "Credit"
//                     : row.orderPaymentVia === 5
//                     ? "UPI"
//                     : ""}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//       <div className="pagination">
//         <Pagination
//           productsPerPage={10}
//           totalProducts={totalorder}
//           currentPage={currentpage}
//           paginate={setCurrentpage}
//         />
//       </div>
//     </div>
//   );
// }

// export default ReportTable;
// import React, { useEffect, useState, useRef } from "react";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { Button } from "primereact/button";
// import { Dialog } from "primereact/dialog";
// import { InputText } from "primereact/inputtext";
// import {
//   FaRegEdit,
//   FaRegFilePdf,
//   FaRegFileExcel,
//   FaRegTrashAlt,
// } from "react-icons/fa";
// import * as XLSX from "xlsx";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import Pagination from "../../components/Pagination/Pagination"; // Assuming pagination component still needed

// import { reportsByDate } from "../../services/services";

// const ReportTable = ({
//   startDate,
//   endDate,
//   pageTitle = "Report Table",
//   emptyMessage = "No records found",
// }) => {
//   const [datereports, setDatereports] = useState([]);
//   const [selectedProducts, setSelectedProducts] = useState(null);
//   const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [searchText, setSearchText] = useState("");
//   const dt = useRef(null);

//   const gettingReportsByDate = async () => {
//     try {
//       // Assuming reportsByDate is still fetching the report data
//       const data = await reportsByDate(startDate, endDate, 1); // Add pagination as needed
//       setDatereports(data.Orders);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     gettingReportsByDate();
//   }, [startDate, endDate]);

//   const exportPDF = () => {
//     const doc = new jsPDF();
//     doc.text(pageTitle, 10, 10);

//     const columns = [
//       "S.No",
//       "OrderId",
//       "Products",
//       "Order Date",
//       "Total",
//       "Payment Type",
//     ];
//     const rows = datereports.map((row, index) => [
//       index + 1,
//       row.orderId,
//       row.orderproducts.map((product) => product.product.title).join(", "),
//       row.createdAt ? new Date(row.createdAt).toLocaleDateString() : "N/A",
//       row.orderTotal,
//       row.orderPaymentVia === 1
//         ? "Cash"
//         : row.orderPaymentVia === 2
//         ? "Credit"
//         : row.orderPaymentVia === 5
//         ? "UPI"
//         : "",
//     ]);

//     doc.autoTable({
//       head: [columns],
//       body: rows,
//       theme: "striped",
//     });

//     doc.save(`${pageTitle}.pdf`);
//   };

//   const exportExcel = () => {
//     const worksheet = XLSX.utils.json_to_sheet(datereports);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
//     XLSX.writeFile(workbook, `${pageTitle}.xlsx`);
//   };

//   const confirmDeleteProduct = (product) => {
//     setSelectedProduct(product);
//     setDeleteDialogVisible(true);
//   };

//   const deleteProduct = () => {
//     // Your delete logic here (e.g., calling onDelete)
//     setDeleteDialogVisible(false);
//     setSelectedProduct(null);
//   };

//   const rightToolbarTemplate = () => (
//     <div className="d-flex">
//       <Button
//         label="Add"
//         icon="pi pi-plus"
//         className="button primary-btn"
//         // onClick={onAddNew}  // Uncomment when needed
//       />
//       <Button
//         label={<FaRegFilePdf />}
//         className="export_pdf"
//         onClick={exportPDF}
//       />
//       <Button
//         label={<FaRegFileExcel />}
//         className="export_excel"
//         onClick={exportExcel}
//       />
//     </div>
//   );

//   const leftToolbarTemplate = () => (
//     <div className="p-input-icon-left">
//       <InputText
//         type="search"
//         value={searchText}
//         onChange={(e) => {
//           setSearchText(e.target.value);
//           dt.current.filter(e.target.value, "global", "contains");
//         }}
//         placeholder="Search..."
//       />
//     </div>
//   );

//   const serialNumberColumn = {
//     header: "sno",
//     body: (rowData, { rowIndex }) => rowIndex + 1,
//     style: { textAlign: "center", width: "6rem" },
//   };

//   return (
//     <div>
//       <div className="table-title">
//         <h4 className="title">{pageTitle}</h4>
//       </div>
//       <div className="cards">
//         <DataTable
//           ref={dt}
//           value={datereports}
//           paginator
//           dataKey="id"
//           rows={10}
//           rowsPerPageOptions={[10, 25]}
//           globalFilter={searchText}
//           emptyMessage={emptyMessage}
//           paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
//           currentPageReportTemplate="Showing {first} to {last} results of {totalRecords} "
//         >
//           <Column
//             header={serialNumberColumn.header}
//             body={serialNumberColumn.body}
//             style={serialNumberColumn.style}
//             className="serial-number-column"
//           />
//           <Column field="orderId" header="OrderId" sortable />
//           <Column
//             field="orderproducts"
//             header="Products"
//             body={(row) =>
//               row.orderproducts
//                 .map((product) => product.product.title)
//                 .join(", ")
//             }
//           />
//           <Column field="createdAt" header="Order Date" sortable />
//           <Column field="orderTotal" header="Total" sortable />
//           <Column
//             field="orderPaymentVia"
//             header="Payment Type"
//             body={(row) =>
//               row.orderPaymentVia === 1
//                 ? "Cash"
//                 : row.orderPaymentVia === 2
//                 ? "Credit"
//                 : row.orderPaymentVia === 5
//                 ? "UPI"
//                 : ""
//             }
//           />
//           <Column
//             header="Action"
//             body={(rowData) => (
//               <div className="d-flex">
//                 <button
//                   className="lites-btn"
//                   onClick={() => console.log("View clicked", rowData)} // onView(rowData)
//                 >
//                   <FaRegEdit />
//                 </button>
//                 <button
//                   className="deletes-btns"
//                   onClick={() => confirmDeleteProduct(rowData)}
//                 >
//                   <FaRegTrashAlt />
//                 </button>
//               </div>
//             )}
//             style={{ textAlign: "center", width: "8rem" }}
//           />
//         </DataTable>
//       </div>

//       <Dialog
//         visible={deleteDialogVisible}
//         style={{ width: "450px" }}
//         header="Confirm"
//         modal
//         onHide={() => setDeleteDialogVisible(false)}
//         footer={
//           <div className="btn-wrapper-flex">
//             <button btnName="No" onSave={() => setDeleteDialogVisible(false)} />
//             <button btnName="Yes" onSave={deleteProduct} />
//           </div>
//         }
//       >
//         <div>Are you sure you want to delete this order?</div>
//       </Dialog>
//     </div>
//   );
// };

// export default ReportTable;
import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Skeleton } from "primereact/skeleton";
import PropTypes from "prop-types";
import { LuEye } from "react-icons/lu";
import {
  FaRegEdit,
  FaRegFileExcel,
  FaRegFilePdf,
  FaRegTrashAlt,
} from "react-icons/fa";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./Table.scss";

const ProductTable = ({
  data = [],
  columns = [],
  deleteConfirmation,
  onDelete,
  onAddNew,
  onAddNewSecond,
  onEdit,
  onView,
  enableAddNew = false,
  enableSecondAddNew = false,
  enableDeleteSelected = false,
  enableView = false,
  enableEdit = false,
  enableDelete = false,
  enableSearch = false,
  onDeleteSelected,
  btnLabel = "Add",
  secondBtnLabel = "",
  pageTitle = "Table",
  emptyMessage = "No records found",
  showAction = true,
  enableExportPdf = false,
  enableExportExcel = false,
  deleteSelectedConfirmation,
  totalRecords = 0,
  loading = false,
  onPageChange,
  rowchange,
  exportData,
  rowsPerPageOptions,
  currentPage = 0,
  onSort,
  columnexcel,
  sortField,
  sortOrder,
  onSearch,
}) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [currentPages, setCurrentPages] = useState(0);
  const [rows, setRows] = useState(10);
  const [searchText, setSearchText] = useState("");
  const dt = useRef(null);
  const startIndex = currentPages * rows;
  const endIndex = startIndex + rows;
  const paginatedData = data.slice(startIndex, endIndex);
  console.log(paginatedData, "paginatedadated");
  const exportPDF = useCallback(() => {
    const doc = new jsPDF();
    doc.text(pageTitle, 10, 10); // Title of the document

    const headers = columns.map((col) => col.header);
    // Get headers from columns
    // const tableData = data.map(
    //   (row) => columns.map((col) => row[col.field] || "N/A") // Extract corresponding fields from row
    // );
    const tableData = data.map((row) => {
      console.log(columnexcel, "table");

      return columnexcel.map((col) => {
        if (col.body) {
          console.log("hiiiiiiiiii");
          return col.body(row);
        }
        console.log(col, "colllll");
        return row[col.field] || "N/A"; // Default for simple fields
      });
    });
    // Add autoTable with headers and body

    const columnWidth = 22;
    doc.autoTable({
      head: [headers],
      body: tableData,
      columnStyles: {
        0: { cellWidth: columnWidth }, // Order ID
        1: { cellWidth: columnWidth }, // Order Date
        2: { cellWidth: columnWidth * 3 }, // Title
        3: { cellWidth: columnWidth * 2 }, // User Details (wider space for text)
        4: { cellWidth: columnWidth }, // Payment Mode
        5: { cellWidth: columnWidth }, // Total
      },
    });
    console.log(tableData, "table data");
    // Save the PDF
    doc.save(`${pageTitle}.pdf`);
  }, [columns, data, pageTitle]);

  const exportExcel = useCallback(() => {
    // Map table rows
    const tableData = data.map((row) => {
      return columnexcel.map((col) => {
        if (col.body) {
          return col.body(row);
        }
        return row[col.field] || "N/A";
      });
    });

    const headers = columns.map((col) => col.header);

    const exportData = [headers, ...tableData];

    const worksheet = XLSX.utils.aoa_to_sheet(exportData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    XLSX.writeFile(workbook, `${pageTitle}.xlsx`);
  }, [data, columns, pageTitle]);
  const handleSearchChange = useCallback((e) => {
    const keyword = e.target.value;
    setSearchText(keyword);

    // if (keyword.length >= 3 || keyword.length === 0) {
    //   onSearch(keyword);
    // }
  }, []);

  const handlePageChange = (event) => {
    setCurrentPages(event.page);
    setRows(event.rows);
  };
  const handleRowsPerPageChange = (event) => {
    setRows(event.rows); // Update rows per page
    setCurrentPages(0);
    console.log("hello"); // Reset to the first page
  };
  const serialNumberColumn = useMemo(
    () => ({
      header: "S.No",
      body: (rowData, { rowIndex }) => rowIndex + 1,
      style: { textAlign: "center", width: "6rem" },
    }),
    []
  );

  // console.log(paginatedData, "paginated");
  // const filteredData = data.filter((row) =>
  //   row.orderproducts.some((item) =>
  //     item.product.title.toLowerCase().includes(searchText.toLowerCase())
  //   )
  // );
  const filteredData = data.filter((row) => {
    // Convert the `row` (excluding `orderproducts`) to a string and search
    const rowString = JSON.stringify({ ...row, orderproducts: undefined })
      .toLowerCase()
      .includes(searchText.toLowerCase());

    // Search within `orderproducts`
    const orderProductsString = row.orderproducts.some((item) =>
      JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
    );

    // Return true if searchText is found in either `row` or `orderproducts`
    return rowString || orderProductsString;
  });

  console.log(searchText, "jdiuifuod");
  useEffect(() => {
    console.log(filteredData, "filterdata");
    console.log(data, "data");
    console.log(paginatedData, startIndex, endIndex, "paginatedadated");
  }, [filteredData]);
  const paymentTypeBody = (rowData) =>
    rowData.orderPaymentVia === 1
      ? "COD"
      : rowData.orderPaymentVia === 5
      ? "UPI"
      : rowData.orderPaymentVia === 6
      ? "Card"
      : "";

  const actionBody = (rowData) => (
    <div className="d-flex">
      {enableView && (
        <Button className="lites-btn" onClick={() => onView(rowData)}>
          <LuEye />
        </Button>
      )}
      {enableEdit && (
        <Button className="lites-btn" onClick={() => onEdit(rowData)}>
          <FaRegEdit />
        </Button>
      )}
      {enableDelete && (
        <Button
          className="deletes-btns"
          onClick={() => {
            setSelectedProduct(rowData);
            setDeleteDialogVisible(true);
          }}
        >
          <FaRegTrashAlt />
        </Button>
      )}
    </div>
  );

  const renderTableContent = () => {
    if (loading) {
      return (
        <DataTable
          value={Array.from({ length: rows })}
          className="p-datatable-striped product-table-skeleton"
        >
          <Column
            header={<Skeleton width="30%" />}
            body={<Skeleton shape="circle" size="2rem" />}
          />
          {columns.map((_, i) => (
            <Column
              key={i}
              header={<Skeleton width="50%" />}
              body={<Skeleton width="80%" />}
            />
          ))}
          {showAction && (
            <Column
              header={<Skeleton width="50%" />}
              body={
                <div className="d-flex justify-content-center">
                  <Skeleton shape="circle" size="2rem" className="mr-2" />
                  <Skeleton shape="circle" size="2rem" className="mr-2" />
                  <Skeleton shape="circle" size="2rem" />
                </div>
              }
            />
          )}
        </DataTable>
      );
    } else {
      return (
        <DataTable
          ref={dt}
          value={searchText ? filteredData : paginatedData}
          paginator
          rows={rows}
          rowsPerPageOptions={rowsPerPageOptions}
          onRowsPerPageChange={handleRowsPerPageChange}
          totalRecords={searchText ? filteredData.length : totalRecords}
          lazy
          first={currentPages * rows}
          onPage={handlePageChange}
          onRow={handleRowsPerPageChange}
          loading={loading}
          sortField={sortField}
          sortOrder={sortOrder === "ASC" ? 1 : -1}
          onSort={(e) => onSort(e.sortField)}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          emptyMessage={emptyMessage}
          globalFilter={searchText}
        >
          <Column
            header={serialNumberColumn.header}
            body={serialNumberColumn.body}
            style={serialNumberColumn.style}
          />
          {columns.map((column) => (
            <Column
              key={column.field}
              field={column.field}
              header={column.header}
              sortable={column.sortable}
              style={column.style}
              body={column.body || null}
            />
          ))}
          {/* <Column header="OrderID" body={(rowData) => rowData.orderId} />
          {columns.map((column) => (
            <Column
              key={column.field}
              field={column.field}
              header={column.header}
              sortable={column.sortable}
              style={column.style}
              body={column.body || null}
            />
          ))}
          <Column
            header="Product Title"
            body={
              (rowData) =>
                rowData.orderproducts.map((product) => (
                  <p className="txt-lf">{product.product.title}</p>
                ))
              // .join(", ")
            } // Join titles if multiple products exist

            // style={{ width: "30%" }} // Adjust the width as necessary
          />
          <Column header="Payment Type" body={paymentTypeBody} /> */}
          {showAction && <Column header="Action" body={actionBody} />}
        </DataTable>
      );
    }
  };

  return (
    <div>
      <div className="table-title">
        <h4 className="title">{pageTitle}</h4>
      </div>
      <div className="cards">
        <Toolbar
          className="mb-15"
          right={
            <div className="d-flex">
              {enableAddNew && (
                <Button
                  label={btnLabel}
                  className="primary-btn"
                  onClick={onAddNew}
                />
              )}
              {enableSecondAddNew && (
                <Button
                  label={secondBtnLabel}
                  className="primary-btn"
                  onClick={onAddNewSecond}
                />
              )}
              {enableExportPdf && (
                <Button
                  icon={<FaRegFilePdf />}
                  className="export_pdf"
                  onClick={exportPDF}
                />
              )}
              {enableExportExcel && (
                <Button
                  icon={<FaRegFileExcel />}
                  className="export_excel"
                  onClick={exportExcel}
                />
              )}
            </div>
          }
          // left={
          //   enableSearch && (
          //     <InputText
          //       type="search"
          //       value={searchText}
          //       onChange={handleSearchChange}
          //       placeholder="Search..."
          //     />
          //   )
          // }
        />
        <label>Search</label>
        <input
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        {renderTableContent()}
      </div>

      <Dialog
        visible={deleteDialogVisible}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        onHide={() => setDeleteDialogVisible(false)}
        footer={
          <div className="btn-wrapper-flex">
            <Button label="No" onClick={() => setDeleteDialogVisible(false)} />
            <Button
              label="Yes"
              onClick={() => {
                onDelete(selectedProduct);
                setDeleteDialogVisible(false);
              }}
            />
          </div>
        }
      >
        <div>{deleteConfirmation}</div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        header="Confirm"
        modal
        onHide={() => setDeleteProductsDialog(false)}
        footer={
          <div className="d-flex justify-content-end">
            <Button
              label="No"
              className="lite-btn"
              onClick={() => setDeleteProductsDialog(false)}
            />
            <Button
              label="Yes"
              className="delete-btns"
              onClick={() => {
                onDeleteSelected(selectedProducts);
                setDeleteProductsDialog(false);
              }}
            />
          </div>
        }
      >
        <div className="confirmation-content">
          <span>{deleteSelectedConfirmation}</span>
        </div>
      </Dialog>
    </div>
  );
};

ProductTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array,
  onDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onAddNewSecond: PropTypes.func,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  enableAddNew: PropTypes.bool,
  enableSecondAddNew: PropTypes.bool,
  enableDeleteSelected: PropTypes.bool,
  enableExportPdf: PropTypes.bool,
  enableExportExcel: PropTypes.bool,
  enableEdit: PropTypes.bool,
  enableView: PropTypes.bool,
  enableDelete: PropTypes.bool,
  enableSearch: PropTypes.bool,
  totalRecords: PropTypes.number,
  loading: PropTypes.bool,
  onPageChange: PropTypes.func,
  rows: PropTypes.number,
  rows: PropTypes.array,
  currentPage: PropTypes.number,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  onSort: PropTypes.func,
  onSearch: PropTypes.func,
  deleteConfirmation: PropTypes.string,
  deleteSelectedConfirmation: PropTypes.string,
};

export default ProductTable;
