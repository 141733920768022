// import React from "react";
// import { FaCheckCircle } from "react-icons/fa"; // Using the check circle icon from react-icons
// import "./PaymentSucess.scss";
// import { useLocation } from "react-router-dom";

// function PaymentSuccess() {
//   const location = useLocation();
//   const order = location.state?.order;
//   console.log(order.orderTotal, "successpage");
//   const paymentAmount = order[0].orderTotal;
//   const paymentDate = new Date(order[0].createdAt).toLocaleString("en-US", {
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//     hour: "2-digit",
//     minute: "2-digit",
//     second: "2-digit",
//     hour12: true,
//   });
//   console.log("Payment Date:", paymentDate);

//   const method = order[0].orderPaymentVia;
//   let paymentMethod = "";
//   if (method == 1) {
//     paymentMethod = "Cash on Delivery";
//   }
//   if (method == 5) {
//     paymentMethod = "UPI";
//   }

//   return (
//     <div className="payment-success-container">
//       {/* Large Green Circle with Checkmark */}
//       <div className="success-icon">
//         <FaCheckCircle size={100} color="#fff" />
//       </div>

//       <div className="payment-info">
//         <h1 className="payment-status">Payment Successful</h1>

//         <div className="payment-details">
//           <p>
//             <strong>Amount:</strong> {paymentAmount}
//           </p>
//           <p>
//             <strong>Date and Time:</strong> {paymentDate}
//           </p>
//           <p>
//             <strong>Payment Method:</strong> {paymentMethod}
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default PaymentSuccess;
// // import React from "react";
// // import PropTypes from "prop-types";
// // import SaveButton from "../../components/Button/SaveButton";
// // import Constant from "../../constant/constant";
// // import muthu_logo from "../../assets/images/muthu_logo.png";
// // import { useReactToPrint } from "react-to-print";
// // import jsPDF from "jspdf";
// // import html2canvas from "html2canvas";

// // const InvoiceDetail = ({ invoiceData, handleClosePopup }) => {
// //   const {
// //     orderSubTotal,
// //     orderShippingPrice,
// //     orderOfferAmount,
// //     orderTotal,
// //     orderproducts,
// //     user,
// //     address,
// //     vendor,
// //   } = invoiceData;

// //   const handleClose = () => {
// //     handleClosePopup();
// //   };

// //   const componentRef = React.useRef();

// //   // Function to handle printing using react-to-print
// //   const handlePrint = useReactToPrint({
// //     content: () => componentRef.current,
// //   });

// //   // Function to handle downloading PDF
// //   const handleDownloadPDF = () => {
// //     const doc = new jsPDF();
// //     doc.text("Invoice Detail", 10, 10);
// //     // Generate PDF content from component
// //     doc.fromHTML(componentRef.current, 15, 15);
// //     doc.save("invoice.pdf");
// //   };

// //   const renderAddress = (label, address) => (
// //     <div>
// //       <h4>{label}</h4>
// //       <p>
// //         {address?.firstName} {address?.lastName}
// //       </p>
// //       <p>{address?.address}</p>
// //       <p>{address?.city?.name}</p>
// //       <p>{address?.state?.name}</p>
// //       <p>{address?.country?.name}</p>
// //     </div>
// //   );
// //   console.log(vendor);
// //   return (
// //     <div className="invoice">
// //       <div class="popup-overlay">
// //         <div class="popup-content">
// //           {" "}
// //           <h4>{Constant.invoice_title}</h4>
// //           <div className="diply-felx">
// //             <img src={muthu_logo} alt="Vendor Logo" />
// //             <div>
// //               <div>
// //                 {Constant.gst_no}: {vendor?.gstNumber || "N/A"}
// //               </div>
// //               <div>
// //                 {Constant.shopname}: {vendor?.shopName || "N/A"}
// //               </div>
// //               <div>
// //                 {Constant.email}: {vendor?.email || "N/A"}
// //               </div>
// //               <div>
// //                 {Constant.address}: {vendor?.address || "N/A"}
// //               </div>
// //             </div>
// //           </div>
// //           <div>
// //             <h4>
// //               {Constant.username}: {user?.firstName} {user?.lastName}
// //             </h4>
// //             <div className="diply-felx">
// //               {renderAddress("Billing Address", address)}
// //               {renderAddress("Shipping Address", address)}
// //             </div>
// //           </div>
// //           <div>
// //             <table>
// //               <thead>
// //                 <tr>
// //                   <th>{Constant.id}</th>
// //                   <th>{Constant.product_name}</th>
// //                   <th>{Constant.price}</th>
// //                   <th>{Constant.quantity}</th>
// //                   <th>{Constant.sub_total}</th>
// //                 </tr>
// //               </thead>
// //               <tbody>
// //                 {orderproducts.map((item, index) => (
// //                   <tr key={item.id}>
// //                     <td>{index + 1}</td>
// //                     <td>
// //                       <span className="pro-name">
// //                         {item.product?.title}
// //                         {item.variant?.variantAttributes.map((attr) => (
// //                           <div key={attr.id}>
// //                             {attr.attribute.name}: {attr.attribute_value.name}
// //                           </div>
// //                         ))}
// //                       </span>
// //                     </td>

// //                     <td>{item?.salePrice}</td>
// //                     <td>{item.quantity}</td>
// //                     <td>{(item?.salePrice * item.quantity).toFixed(2)}</td>
// //                   </tr>
// //                 ))}
// //               </tbody>
// //             </table>
// //             <div className="shipping-cost">
// //               <div>
// //                 <span>
// //                   <h4>{Constant.sub_total}</h4>
// //                 </span>
// //                 <span>
// //                   <h4>{orderSubTotal}</h4>
// //                 </span>
// //               </div>
// //               <div>
// //                 <span>
// //                   <h4>{Constant.shipping_Cost}</h4>
// //                 </span>
// //                 <span>
// //                   <h4>{orderShippingPrice}</h4>
// //                 </span>
// //               </div>
// //               <div>
// //                 <span>
// //                   <h4>{Constant.discount}</h4>
// //                 </span>
// //                 <span>
// //                   <h4>{orderOfferAmount}</h4>
// //                 </span>
// //               </div>
// //               <div>
// //                 <span>
// //                   <h4>{Constant.total}</h4>
// //                 </span>
// //                 <span>
// //                   <h4>{orderTotal}</h4>
// //                 </span>
// //               </div>
// //             </div>
// //             <div className="print-buttons">
// //               <button onClick={handlePrint}>Print</button>
// //               <button onClick={handleDownloadPDF}>Download PDF</button>
// //             </div>
// //             <SaveButton btnName={Constant.btn_close} onSave={handleClose} />
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // InvoiceDetail.propTypes = {
// //   invoiceData: PropTypes.shape({
// //     orderSubTotal: PropTypes.number.isRequired,
// //     orderShippingPrice: PropTypes.number.isRequired,
// //     orderOfferAmount: PropTypes.number.isRequired,
// //     orderTotal: PropTypes.number.isRequired,
// //     orderproducts: PropTypes.arrayOf(
// //       PropTypes.shape({
// //         id: PropTypes.string.isRequired,
// //         product: PropTypes.shape({
// //           title: PropTypes.string,
// //           price: PropTypes.number,
// //         }),
// //         variant: PropTypes.shape({
// //           variantAttributes: PropTypes.arrayOf(
// //             PropTypes.shape({
// //               id: PropTypes.string,
// //               attribute: PropTypes.shape({
// //                 name: PropTypes.string,
// //               }),
// //               attribute_value: PropTypes.shape({
// //                 name: PropTypes.string,
// //               }),
// //             })
// //           ),
// //         }),
// //         quantity: PropTypes.number,
// //       })
// //     ).isRequired,
// //     user: PropTypes.shape({
// //       firstName: PropTypes.string,
// //       lastName: PropTypes.string,
// //     }),
// //     address: PropTypes.shape({
// //       firstName: PropTypes.string,
// //       lastName: PropTypes.string,
// //       address: PropTypes.string,
// //       city: PropTypes.shape({
// //         name: PropTypes.string,
// //       }),
// //       state: PropTypes.shape({
// //         name: PropTypes.string,
// //       }),
// //       country: PropTypes.shape({
// //         name: PropTypes.string,
// //       }),
// //     }),
// //     vendor: PropTypes.shape({
// //       vendorlogo: PropTypes.string,
// //       gstNumber: PropTypes.string,
// //       shopName: PropTypes.string,
// //       email: PropTypes.string,
// //       address: PropTypes.string,
// //     }),
// //   }).isRequired,
// // };

// // export default InvoiceDetail;
// import React, { useRef } from "react";
// import PropTypes from "prop-types";
// import SaveButton from "../../components/Button/SaveButton";
// import Constant from "../../constant/constant";
// import muthu_logo from "../../assets/images/muthu_logo.png";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";

// const InvoiceDetail = ({ invoiceData, handleClosePopup }) => {
//   const {
//     orderSubTotal,
//     orderShippingPrice,
//     orderOfferAmount,
//     orderTotal,
//     orderproducts,
//     user,
//     address,
//     vendor,
//   } = invoiceData;

//   const invoiceRef = useRef(); // To capture the invoice content

//   const handleClose = () => {
//     handleClosePopup();
//   };

//   const renderAddress = (label, address) => (
//     <div>
//       <h4>{label}</h4>
//       <p>
//         {address?.firstName} {address?.lastName}
//       </p>
//       <p>{address?.address}</p>
//       <p>{address?.city?.name}</p>
//       <p>{address?.state?.name}</p>
//       <p>{address?.country?.name}</p>
//     </div>
//   );

//   // Function to handle PDF Download with improved quality
//   const handleDownloadPDF = () => {
//     const invoiceElement = invoiceRef.current;

//     // Temporarily hide unwanted elements during PDF generation
//     const elementsToHide = invoiceElement.querySelectorAll(".no-pdf");
//     elementsToHide.forEach((el) => (el.style.display = "none"));

//     html2canvas(invoiceElement, {
//       scale: 3, // Increase the scale to improve PDF quality
//     }).then((canvas) => {
//       const imgData = canvas.toDataURL("image/png");
//       const doc = new jsPDF({
//         orientation: "portrait",
//         unit: "px",
//         format: "a4",
//       });

//       const imgWidth = doc.internal.pageSize.getWidth();
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;

//       doc.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
//       doc.save("invoice.pdf");

//       // Restore visibility of hidden elements
//       elementsToHide.forEach((el) => (el.style.display = ""));
//     });
//   };

//   return (
//     <div className="invoice">
//       <div className="popup-overlay no-print">
//         <div className="popup-content" ref={invoiceRef}>
//           <h4>{Constant.invoice_title}</h4>
//           <div className="diply-felx">
//             <img src={muthu_logo} alt="Vendor Logo" />
//             <div>
//               <div>
//                 {Constant.gst_no}: {vendor?.gstNumber || "N/A"}
//               </div>
//               <div>
//                 {Constant.shopname}: {vendor?.shopName || "N/A"}
//               </div>
//               <div>
//                 {Constant.email}: {vendor?.email || "N/A"}
//               </div>
//               <div>
//                 {Constant.address}: {vendor?.address || "N/A"}
//               </div>
//             </div>
//           </div>
//           <div>
//             <h4>
//               {Constant.username}: {user?.firstName} {user?.lastName}
//             </h4>
//             <div className="diply-felx">
//               {renderAddress("Billing Address", address)}
//               {renderAddress("Shipping Address", address)}
//             </div>
//           </div>
//           <div>
//             <table>
//               <thead>
//                 <tr>
//                   <th>{Constant.id}</th>
//                   <th>{Constant.product_name}</th>
//                   <th>{Constant.price}</th>
//                   <th>{Constant.quantity}</th>
//                   <th>{Constant.sub_total}</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {orderproducts.map((item, index) => (
//                   <tr key={item.id}>
//                     <td>{index + 1}</td>
//                     <td>
//                       <span className="pro-name">
//                         {item.product?.title}
//                         {item.variant?.variantAttributes.map((attr) => (
//                           <div key={attr.id}>
//                             {attr.attribute.name}: {attr.attribute_value.name}
//                           </div>
//                         ))}
//                       </span>
//                     </td>
//                     <td>{item?.salePrice}</td>
//                     <td>{item.quantity}</td>
//                     <td>{(item?.salePrice * item.quantity).toFixed(2)}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//             <div className="shipping-cost">
//               <div>
//                 <span>
//                   <h4>{Constant.sub_total}</h4>
//                 </span>
//                 <span>
//                   <h4>{orderSubTotal}</h4>
//                 </span>
//               </div>
//               <div>
//                 <span>
//                   <h4>{Constant.shipping_Cost}</h4>
//                 </span>
//                 <span>
//                   <h4>{orderShippingPrice}</h4>
//                 </span>
//               </div>
//               <div>
//                 <span>
//                   <h4>{Constant.discount}</h4>
//                 </span>
//                 <span>
//                   <h4>{orderOfferAmount}</h4>
//                 </span>
//               </div>
//               <div>
//                 <span>
//                   <h4>{Constant.total}</h4>
//                 </span>
//                 <span>
//                   <h4>{orderTotal}</h4>
//                 </span>
//               </div>
//             </div>
//             <div className="no-pdf no-print">
//               <SaveButton btnName={Constant.btn_close} onSave={handleClose} />
//               <button onClick={handleDownloadPDF} className="btn-download-pdf">
//                 Download PDF
//               </button>
//               <button onClick={() => window.print()} className="btn-print">
//                 Print Invoice
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// InvoiceDetail.propTypes = {
//   invoiceData: PropTypes.shape({
//     orderSubTotal: PropTypes.number.isRequired,
//     orderShippingPrice: PropTypes.number.isRequired,
//     orderOfferAmount: PropTypes.number.isRequired,
//     orderTotal: PropTypes.number.isRequired,
//     orderproducts: PropTypes.arrayOf(
//       PropTypes.shape({
//         id: PropTypes.string.isRequired,
//         product: PropTypes.shape({
//           title: PropTypes.string,
//         }),
//         variant: PropTypes.shape({
//           variantAttributes: PropTypes.arrayOf(
//             PropTypes.shape({
//               id: PropTypes.string,
//               attribute: PropTypes.shape({
//                 name: PropTypes.string,
//               }),
//               attribute_value: PropTypes.shape({
//                 name: PropTypes.string,
//               }),
//             })
//           ),
//         }),
//         quantity: PropTypes.number,
//         salePrice: PropTypes.number,
//       })
//     ).isRequired,
//     user: PropTypes.shape({
//       firstName: PropTypes.string,
//       lastName: PropTypes.string,
//     }),
//     address: PropTypes.shape({
//       firstName: PropTypes.string,
//       lastName: PropTypes.string,
//       address: PropTypes.string,
//       city: PropTypes.shape({
//         name: PropTypes.string,
//       }),
//       state: PropTypes.shape({
//         name: PropTypes.string,
//       }),
//       country: PropTypes.shape({
//         name: PropTypes.string,
//       }),
//     }),
//     vendor: PropTypes.shape({
//       gstNumber: PropTypes.string,
//       shopName: PropTypes.string,
//       email: PropTypes.string,
//       address: PropTypes.string,
//     }),
//   }).isRequired,
//   handleClosePopup: PropTypes.func.isRequired,
// };

// export default InvoiceDetail;

import { useLocation } from "react-router-dom";
import InvoiceDetail from "./InvoiceDetail";

const SuccessPage = () => {
  const location = useLocation();
  const { invoiceData } = location.state || {}; // Safely access invoiceData

  if (!invoiceData) {
    return <div>No invoice data found.</div>;
  }

  return <InvoiceDetail invoiceData={invoiceData} />;
};

export default SuccessPage;
