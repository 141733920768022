import React, { useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Button from "../../components/button/Button";

const Register = () => {
  const navigate = useNavigate();
  const { register } = useContext(AuthContext);
  const [registerError, setRegisterError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const result = await register(
        values.firstName,
        values.lastName,
        values.email,
        values.phoneNumber,
        values.password
      );
      if (result.success) {
        resetForm();
        navigate("/");
        toast.success("User registered successfully");
        window.location.reload();
      } else {
        setRegisterError(result.message);
      }
    } catch (error) {
      setRegisterError("An error occurred. Please try again later.");
    }
    setSubmitting(false);
  };

  return (
    <div className="signup">
      <div className="signup-container">
        <div className="signin-img">
          <img class="part1" src="/muthu-logo.png" alt="Login" />
          <img class="part2" src="/muthu-logo-text.png" alt="Login" />
        </div>

        <div className="signin-form">
          <h2 className="signup-heading">Sign Up</h2>
          <div className="login-form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-wrapper">
                    <label htmlFor="firstName">
                      <span>Username</span>
                      <Field type="text" id="firstName" name="firstName" />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error"
                      />
                    </label>

                    <label htmlFor="email">
                      <span>Email Address</span>
                      <Field type="email" id="email" name="email" />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </label>

                    <label htmlFor="password">
                      <span>Password</span>
                      <div
                        className="password-container"
                        style={{ position: "relative" }}
                      >
                        <Field
                          type={passwordVisible ? "text" : "password"}
                          id="password"
                          name="password"
                          className="password-input" // Add any class for styling if needed
                        />
                        {/* Eye icon to toggle visibility */}
                        <i
                          onClick={() => setPasswordVisible(!passwordVisible)}
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "15px",
                            top: "35%",
                          }}
                        >
                          {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                        </i>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error"
                      />
                    </label>

                    {/* Confirm Password Field */}
                    <label htmlFor="confirmPassword">
                      <span>Confirm Password</span>
                      <div
                        className="password-container"
                        style={{ position: "relative" }}
                      >
                        <Field
                          type={confirmPasswordVisible ? "text" : "password"}
                          id="confirmPassword"
                          name="confirmPassword"
                          className="password-input"
                        />
                        {/* Eye icon to toggle visibility */}
                        <i
                          onClick={() =>
                            setConfirmPasswordVisible(!confirmPasswordVisible)
                          }
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "15px",
                            top: "35%",
                          }}
                        >
                          {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                        </i>
                      </div>
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="error"
                      />
                    </label>
                    <Button
                      type="submit"
                      label="Sign Up"
                      className="btn-primary"
                      onClick={() => navigate("/sign-up")}
                    />
                  </div>
                </Form>
              )}
            </Formik>

            <div className="divider">
              <span className="divider-text">OR</span>
            </div>

            <div className="login-link">
              <span>Already have an account? </span>
              <a href="/sign-in" className="login-anchor">
                Log in here
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
