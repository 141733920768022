// HamburgerMenu.js
import React from "react";
import { FaBars } from "react-icons/fa";
import "./HamburgerMenu.scss";

const HamburgerMenu = () => {
  return (
    <div className="hamburger-component">
      <div>
        <FaBars />
      </div>
    </div>
  );
};

export default HamburgerMenu;
