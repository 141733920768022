import React from "react";
import Header from "../../components/header/Header";
import PaymentSuccess from "../../components/Payment/PaymentSucess";
import "../../components/Payment/PaymentSucess";

function SuccessPage() {
  return (
    <>
      <div className="header">
        <Header />
      </div>

      <div className="success-page-container">
        <PaymentSuccess />
      </div>
    </>
  );
}

export default SuccessPage;
