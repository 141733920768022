// import logo from "./logo.svg";
// import Mainpage from "./pages/Mainpage/Mainpage";
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   useLocation,
//   useNavigate,
// } from "react-router-dom";
// import SignUp from "./pages/signup/SignUp";
// import Register from "./pages/Register/Register";
// import Header from "./components/header/Header";
// import Reports from "./pages/Reports/Reports";
// import Checkout from "./pages/Checkout/Checkout";
// import AuthProvider from "./Context/AuthContext";
// import SuccessPage from "./pages/Payment/SuccessPage";
// import { CartProvider } from "./Context/CartContext";
// import ProductDetail from "./components/Product/ProductDetail";
// import ProductPage from "./components/Product/Product";
// import { useEffect } from "react";
// import Profile from "./components/Profile/Profile";
// import UpdateProfile from "./components/Profile/UpdateProfile";
// import InvoiceDetail from "./components/Payment/InvoiceDetail";
// import ProductDetails from "./components/Product/Product";
// import ViewOrder from "./components/Report/ViewOrder";
// function App1() {
//   return (
//     <AuthProvider>
//       <Router>
//         <CartProvider>
//           <App />
//         </CartProvider>
//       </Router>
//     </AuthProvider>
//   );
// }

// function App() {
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     if (location.pathname == "/") {
//       navigate("/main-page");
//     }
//   }, [navigate]);
//   return (
//     // <Router>
//     <div className="App">
//       <Routes>
//         <Route path="/sign-in" element={<SignUp />} />
//         <Route path="/register" element={<Register />} />
//         <Route path="/main-page" element={<Mainpage />} />
//         <Route path="/checkout" element={<Checkout />} />
//         <Route path="/Reports" element={<Reports />} />
//         <Route path="/invoice-page" element={<SuccessPage />} />
//         <Route path="/profile" element={<UpdateProfile />} />
//         <Route path="/orders/edit/:orderId" element={<ViewOrder />} />
//         <Route path="/test" element={<ProductDetails />} />
//       </Routes>
//     </div>
//     // </Router>
//   );
// }

// export { App, App1 };

import logo from "./logo.svg";
import Mainpage from "./pages/Mainpage/Mainpage";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import SignUp from "./pages/signup/SignUp";
import Register from "./pages/Register/Register";
import Header from "./components/header/Header";
import Reports from "./pages/Reports/Reports";
import Checkout from "./pages/Checkout/Checkout";
import AuthProvider from "./Context/AuthContext";
import SuccessPage from "./pages/Payment/SuccessPage";
import { CartProvider } from "./Context/CartContext";
import ProductDetail from "./components/Product/ProductDetail";
import ProductPage from "./components/Product/Product";
import { useEffect } from "react";
import Profile from "./components/Profile/Profile";
import UpdateProfile from "./components/Profile/UpdateProfile";
import InvoiceDetail from "./components/Payment/InvoiceDetail";
import ProductDetails from "./components/Product/Product";
import ViewOrder from "./components/Report/ViewOrder";
function App1() {
  return (
    <AuthProvider>
      <Router>
        <CartProvider>
          <App />
        </CartProvider>
      </Router>
    </AuthProvider>
  );
}

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname == "/") {
      navigate("/main-page");
    }
  }, [navigate]);
  return (
    // <Router>
    <div className="App">
      <Routes>
        <Route path="/sign-in" element={<SignUp />} />
        <Route path="/register" element={<Register />} />
        <Route path="/main-page" element={<Mainpage />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/Reports" element={<Reports />} />
        <Route path="/invoice-page" element={<SuccessPage />} />
        <Route path="/profile" element={<UpdateProfile />} />
        <Route path="/orders/edit/:orderId" element={<ViewOrder />} />
        <Route path="/test" element={<ProductDetails />} />
      </Routes>
    </div>
    // </Router>
  );
}

export { App, App1 };
