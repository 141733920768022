import axios from "axios";
import { getCookie } from "../utils/TokenUtils";
// import { get } from "lodash";
//
// export const API_BASE_URL = "http://192.168.1.6:3005/api/pos";
// export const API_BASE_URL = "http://162.214.217.36:3006";
// export const API_BASE_URL = "http://localhost:3005/";
export const API_BASE_URL = "https://muthueshop.com:3006/api/pos";
//
//brands api fetch by minar
const token = getCookie("accessToken");

export const generalSettings = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/site-settings`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch", error.response);
  }
};
export const fetchBrands = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/brands`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.brands;
  } catch (error) {
    console.error("Error fetching brands:", error);
    throw error;
  }
};
export const fetchCategories = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/categories?page=1&limit=200`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data.category;
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
};
export const searchProducts = async (query, page, limit) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/products/search?keyword=${query}&page=${page}&limit=${limit}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    return [];
  }
};
//fertch products
export const fetchAllProducts = async (
  currentPage,
  sortOrder,
  sortBy,
  categoryIds = [],
  brandId = []
) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/products?categoryId=${categoryIds}&brandId=${brandId}&limit=30&page=${currentPage}&order=${sortOrder}&sortBy=${sortBy}`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data || [];
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw error;
  }

  // export const fetchAllProducts = async (
  //   currentPage,
  //   sortOrder,
  //   sortBy,
  //   selectedCategories,
  //   selectedBrands
  // ) => {
  //   try {
  //     const categoryIds = selectedCategories.join(",");
  //     const brandIds = selectedBrands.join(",");

  //     const response = await axios.get(
  //       `${API_BASE_URL}/products?categoryId=[${categoryIds}]&brandId=[${brandIds}]&page=${currentPage}&order=${sortOrder}&sortBy=${sortBy}`,
  //       { headers: { Authorization: `Bearer ${token}` } }
  //     );

  //     console.log(response.data, "responseee");
  //     return response.data || [];
  //   } catch (error) {
  //     console.error("Error fetching products:", error);
  //     throw error;
  //   }
};
export const searchProductTest = async (
  currentPage,
  sortOrder,
  sortBy,
  categoryIds = [],
  brandId = [],
  query
) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/products/search?keyword=${query}&categoryId=${categoryIds}&brandId=${brandId}&limit=30&page=${currentPage}&order=${sortOrder}&sortBy=${sortBy}`,

      { headers: { Authorization: `Bearer ${token}` } }
    );
    // console.log(response.data, "search result");

    return response.data || [];
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw error;
  }
};
export const applyCoupon = async (couponName) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/discounts/coupon/${couponName}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data || [];
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw error;
  }
};
export const createUser = async (userInfo, address) => {
  try {
    const data = { userInfo, address };
    const response = await axios.post(`${API_BASE_URL}/users/`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data || [];
  } catch (err) {
    throw err;
  }
};
export const searchUsers = async (query) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/users/search/`, {
      params: { keyword: query },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data || [];
  } catch (error) {
    console.error("Error fetching user search results:", error);
    throw error;
  }
};
export const fetchCountries = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/countries

`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchPaymentMethods = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/paymnent-methods

`);

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const orderCreation = async (requestData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/orders

`,
      requestData,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
// export const reportsByDate = async (
//   startDate,
//   endDate,
//   page = 1,
//   limit = 10
// ) => {
//   try {
//     let response;
//     console.log(typeof startDate, typeof endDate, "date");
//     if (startDate || endDate) {
//       console.log("hello");
//       response = await axios.get(
//         `${API_BASE_URL}/reports?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}

// `,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );

//       console.log(response, "order data");
//     } else {
//       console.log("elseblock");

//       response = await axios.get(
//         `${API_BASE_URL}/reports?page=${page}&limit=${limit}

// `,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//     }

//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };
export const reportsByDate = async (
  startDate,
  endDate,

  searchkey
) => {
  try {
    let queryParams = [];

    if (startDate)
      queryParams.push(`startDate=${encodeURIComponent(startDate)}`);
    if (endDate) queryParams.push(`endDate=${encodeURIComponent(endDate)}`);

    if (searchkey) queryParams.push(`search=${searchkey}`);

    const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";
    const url = `${API_BASE_URL}/reports${queryString}`;

    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(response, "order data");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const paymentTypeCount = async (startDate, endDate = "") => {
  try {
    let response;
    console.log(startDate, endDate, "payment");
    if (startDate || endDate) {
      response = await axios.get(
        `${API_BASE_URL}/reports/sales/payment-methods?startDate=${startDate}&endDate=${endDate}

`,

        { headers: { Authorization: `Bearer ${token}` } }
      );
    } else {
      response = await axios.get(
        `${API_BASE_URL}/reports/sales/payment-methods

`,

        { headers: { Authorization: `Bearer ${token}` } }
      );
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getUserDetails = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/users`,

      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log(response, "user");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getInvoiceByID = async (orderid) => {
  try {
    console.log("order invoice", orderid);
    const response = await axios.get(
      `${API_BASE_URL}/orders/${orderid}`,

      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log(response, "user");
    return response.data;
  } catch (error) {
    throw error;
  }
};
