import React from "react";
import PropTypes from "prop-types";
import "./Button.scss";

const Button = ({ type, disabled, onClick, label, className }) => {
  return (
    <button
      type={type || "button"}
      disabled={disabled}
      onClick={onClick}
      className={`btn ${className}`}
    >
      {label}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
  disabled: false,
  onClick: () => {},
  className: "",
};

export default Button;
