import React, { useEffect, useState } from "react";
import "./logo.scss";
import { useNavigate } from "react-router-dom";
import { generalSettings } from "../../services/services";

const Logo = () => {
  const navigate = useNavigate();
  const [logo, setLogo] = useState();
  const handleLogoClick = () => {
    navigate("/main-page");
  };
  const getLogo = async () => {
    try {
      const logo = await generalSettings();
      console.log(logo, "logooo");
      setLogo(logo.siteSettings.sitelogo.logoUrl);
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    getLogo();
  }, []);

  return (
    <div className="logo">
      <img src={logo} alt="Sign up" onClick={handleLogoClick} />
    </div>
  );
};

export default Logo;
