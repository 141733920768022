// import React, { useState } from "react";
// import "./ProductDetail.scss";

// const ProductDetail = ({ product, onClose }) => {
//   const [selectedColor, setSelectedColor] = useState(null);
//   const [selectedCapacity, setSelectedCapacity] = useState(null);

//   const handleColorSelect = (color) => {
//     setSelectedColor(color); // Set the selected color
//   };

//   const handleCapacitySelect = (capacity) => {
//     setSelectedCapacity(capacity); // Set the selected capacity
//   };

//   return (
//     <div className="product-detail-overlay" onClick={onClose}>
//       <div className="product-detail" onClick={(e) => e.stopPropagation()}>
//         <button className="close-btn" onClick={onClose}>
//           ❌
//         </button>
//         <img
//           src={product.image}
//           alt={product.name}
//           className="modal-product-image"
//         />
//         <h3>{product.name}</h3>

//         <div className="options">
//           {/* Color Options */}
//           <div className="color-options">
//             <h4>Color:</h4>
//             <div className="color-buttons">
//               <button
//                 className={`color-btn ${
//                   selectedColor === "Red" ? "selected" : ""
//                 }`}
//                 style={{ backgroundColor: "#2A2A2A" }}
//                 onClick={() => handleColorSelect("Red")}
//               ></button>
//               <button
//                 className={`color-btn ${
//                   selectedColor === "Blue" ? "selected" : ""
//                 }`}
//                 style={{ backgroundColor: "#80808080" }}
//                 onClick={() => handleColorSelect("Blue")}
//               ></button>
//               <button
//                 className={`color-btn ${
//                   selectedColor === "Green" ? "selected" : ""
//                 }`}
//                 style={{ backgroundColor: "#EB9406" }}
//                 onClick={() => handleColorSelect("Green")}
//               ></button>
//             </div>
//           </div>

//           {/* Capacity Options */}
//           <div className="capacity-options">
//             <h4>Capacity:</h4>
//             <div className="capacity-buttons">
//               <button
//                 className={`capacity-btn ${
//                   selectedCapacity === "1L" ? "selected" : ""
//                 }`}
//                 onClick={() => handleCapacitySelect("1L")}
//               >
//                 1L
//               </button>
//               <button
//                 className={`capacity-btn ${
//                   selectedCapacity === "2L" ? "selected" : ""
//                 }`}
//                 onClick={() => handleCapacitySelect("2L")}
//               >
//                 2L
//               </button>
//               <button
//                 className={`capacity-btn ${
//                   selectedCapacity === "3L" ? "selected" : ""
//                 }`}
//                 onClick={() => handleCapacitySelect("3L")}
//               >
//                 3L
//               </button>
//             </div>
//           </div>
//           <div>
//             <h4>Price: ₹{product.price}</h4>
//           </div>
//         </div>

//         <button className="add-to-cart-btn">Add to Cart</button>
//       </div>
//     </div>
//   );
// };

// export default ProductDetail;
// import React, { useEffect, useState } from "react";
// import "./ProductDetail.scss";
// import { IoClose } from "react-icons/io5";

// const ProductDetail = ({ product, onClose }) => {
//   const [selectedAttributes, setSelectedAttributes] = useState({});
//   const [selectedVariant, setSelectedVariant] = useState(null);
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [variants, setVariants] = useState([]);

//   useEffect(() => {
//     if (product) {
//       setVariants(product.variants || []);
//       setSelectedImage(product.images?.[0] || null);
//       if (product.variants && product.variants.length > 0) {
//         const initialVariant = product.variants[0];
//         setSelectedVariant(initialVariant);
//         // Set default selected attributes from the initial variant
//         const initialAttributes = {};
//         initialVariant.variantAttributes.forEach((attr) => {
//           initialAttributes[attr.attribute.name] = attr.attribute_value.name;
//         });
//         setSelectedAttributes(initialAttributes);
//       }
//     }
//   }, [product]);

//   const attributesNames = Array.from(
//     new Set(
//       variants.flatMap((variant) =>
//         variant.variantAttributes.map((attr) => attr.attribute.name)
//       )
//     )
//   );

//   const getAttributeValues = (attributeName) => {
//     return Array.from(
//       new Set(
//         variants
//           .flatMap((variant) => variant.variantAttributes)
//           .filter((attr) => attr.attribute.name === attributeName)
//           .map((attr) => attr.attribute_value.name)
//       )
//     );
//   };

//   const handleAttributeSelect = (attributeName, value) => {
//     // Update the selected attributes based on user selection
//     const updatedAttributes = {
//       ...selectedAttributes,
//       [attributeName]: value,
//     };
//     setSelectedAttributes(updatedAttributes);

//     // Find the matching variant based on updated attributes
//     const matchingVariant = variants.find((variant) =>
//       variant.variantAttributes.every(
//         (attr) =>
//           updatedAttributes[attr.attribute.name] === attr.attribute_value.name
//       )
//     );
//     if (matchingVariant) {
//       setSelectedVariant(matchingVariant);
//       // Set the image if the variant has a specific image
//       const variantImage = product.images.find(
//         (img) => img.id === matchingVariant.imageId
//       );
//       setSelectedImage(variantImage || product.images[0]); // Fallback to the first product image if no variant image
//     }
//   };

//   return (
//     <div className="product-detail-overlay" onClick={onClose}>
//       <div className="product-detail" onClick={(e) => e.stopPropagation()}>
//         <button className="close-btn" onClick={onClose}>
//           <IoClose />
//         </button>
//         {selectedImage && (
//           <img
//             src={selectedImage.imageUrl}
//             alt={product.name}
//             className="modal-product-image"
//           />
//         )}
//         <h3>{product.name}</h3>
//         <div className="options">
//           {attributesNames.map((attributeName) => (
//             <div key={attributeName} className="attribute-values">
//               <span>{`${attributeName}:`}</span>
//               <div className="size">
//                 {getAttributeValues(attributeName).map((value) => (
//                   <button
//                     key={value}
//                     onClick={() => handleAttributeSelect(attributeName, value)}
//                     className={`button ${
//                       selectedAttributes[attributeName] === value
//                         ? "active"
//                         : ""
//                     }`}
//                     aria-pressed={selectedAttributes[attributeName] === value}
//                   >
//                     {value}
//                   </button>
//                 ))}
//               </div>
//             </div>
//           ))}
//         </div>
//         <div>
//           <h4>Price: ₹{selectedVariant?.salePrice || product.salePrice}</h4>
//         </div>
//         <button className="add-to-cart-btn">Add to Cart</button>
//       </div>
//     </div>
//   );
// };

// export default ProductDetail;

// import React, { useEffect, useState, useContext } from "react";
// import "./ProductDetail.scss";
// import { IoClose } from "react-icons/io5";
// import { CartContext } from "../../Context/CartContext";

// import { toast } from "react-toastify";
// const ProductDetail = ({ product, onClose, addToCart }) => {
//   const [selectedAttributes, setSelectedAttributes] = useState({});
//   const [selectedVariant, setSelectedVariant] = useState(null);
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [quantity, setQuantity] = useState(1);

//   const [variants, setVariants] = useState([]);
//   const { dispatch } = useContext(CartContext);
//   const [imageId, setImageId] = useState([]);
//   console.log(product, "p");
//   useEffect(() => {
//     if (product) {
//       setVariants(product.variants || []);
//       setSelectedImage(product.images?.[0] || null);
//       if (product.variants && product.variants.length > 0) {
//         const initialVariant = product.variants[0];
//         setSelectedVariant(initialVariant);
//         setQuantity(1);
//         const variantImage = product.images.find(
//           (img) => img.id === initialVariant.imageId
//         );
//         setSelectedImage(variantImage || product.images[0]);

//         // Set default selected attributes from the initial variant
//         const initialAttributes = {};
//         console.log(initialVariant, "initial");
//         initialVariant.variantAttributes.forEach((attr) => {
//           initialAttributes[attr.attribute.name] = attr.attribute_value.name;
//         });
//         setSelectedAttributes(initialAttributes);
//         console.log(initialAttributes, "sjg");
//       }
//     }
//   }, [product]);
//   console.log(selectedVariant, "selected variant");
//   const attributesNames = Array.from(
//     new Set(
//       variants.flatMap((variant) =>
//         variant.variantAttributes.map((attr) => attr.attribute.name)
//       )
//     )
//   );
//   const getAttributeValues = (attributeName) => {
//     return Array.from(
//       new Set(
//         variants
//           .flatMap((variant) => variant.variantAttributes)
//           .filter((attr) => attr.attribute.name === attributeName)
//           .map((attr) => attr.attribute_value.name)
//       )
//     );
//   };
//   // const handleAttributeSelect = (attributeName, value) => {
//   //   // Update the selected attributes based on user selection
//   //   const updatedAttributes = {
//   //     ...selectedAttributes,
//   //     [attributeName]: value,
//   //   };
//   //   console.log(updatedAttributes, "updated attributes");
//   //   setSelectedAttributes(updatedAttributes);
//   //   // Find the matching variant based on updated attributes
//   //   const matchingVariant = variants.find((variant) =>
//   //     variant.variantAttributes.every(
//   //       (attr) =>
//   //         updatedAttributes[attr.attribute.name] === attr.attribute_value.name
//   //     )
//   //   );
//   //   if (matchingVariant) {
//   //     setSelectedVariant(matchingVariant);
//   //     // Set the image if the variant has a specific image
//   //     const variantImage = product.images.find(
//   //       (img) => img.id === matchingVariant.imageId
//   //     );
//   //     setSelectedImage(variantImage || product.images[0]); // Fallback to the first product image if no variant image
//   //   }
//   // };
//   const handleAttributeSelect = (attributeName, value) => {
//     const matchingVariant = variants.find((variant) =>
//       variant.variantAttributes.some(
//         (attr) =>
//           attr.attribute.name === attributeName &&
//           attr.attribute_value.name === value
//       )
//     );
//     if (matchingVariant) {
//       const newSelectedAttributes = {};
//       matchingVariant.variantAttributes.forEach((attr) => {
//         newSelectedAttributes[attr.attribute.name] = attr.attribute_value.name;
//       });
//       setSelectedVariant(matchingVariant);
//       setSelectedAttributes(newSelectedAttributes);

//       // If variant has an image, set it
//       if (matchingVariant.imageId) {
//         const variantImage = product.images.find(
//           (img) => img.id === matchingVariant.imageId
//         );
//         if (variantImage) {
//           setSelectedImage(variantImage);
//         }
//       }
//     }
//   };
//   // const handleAddToCart = () => {
//   //   if (selectedVariant) {
//   //     addToCart(selectedVariant, 1); // Add 1 of the selected variant to the cart
//   //   }
//   // };
//   const handleAddToCart = () => {
//     if (!selectedVariant.stock) {
//       toast.error("Stock Not Available");
//       return;
//     }
//     dispatch({
//       type: "ADD_TO_CART",
//       payload: {
//         productDetails: product,
//         variant: selectedVariant,
//         quantity: quantity,
//       },
//     });
//   };
//   return (
//     <div className="product-detail-overlay" onClick={onClose}>
//       <div className="product-detail" onClick={(e) => e.stopPropagation()}>
//         <button className="close-btn" onClick={onClose}>
//           <IoClose />
//         </button>
//         {selectedImage && (
//           <img
//             src={selectedImage.imageUrl}
//             alt={product.name}
//             className="modal-product-image"
//           />
//         )}
//         <h3>{product.name}</h3>
//         <div className="options">
//           {attributesNames.map((attributeName) => (
//             <div key={attributeName} className="attribute-values">
//               <h3>
//                 <span>{`${attributeName}:`}</span>
//               </h3>
//               <div className="size">
//                 {getAttributeValues(attributeName).map((value) => (
//                   <button
//                     key={value}
//                     onClick={() => handleAttributeSelect(attributeName, value)}
//                     className={`button ${
//                       selectedAttributes[attributeName] === value
//                         ? "active"
//                         : ""
//                     }`}
//                     aria-pressed={selectedAttributes[attributeName] === value}
//                   >
//                     {value}
//                   </button>
//                 ))}
//               </div>
//             </div>
//           ))}
//           <div className="attribute-values">
//             <div>
//               <h3>Price:</h3>{" "}
//             </div>
//             <div className="size">
//               <h3> ₹{selectedVariant?.salePrice || product.salePrice}</h3>{" "}
//             </div>
//           </div>
//         </div>

//         <button className="add-to-cart-btn" onClick={handleAddToCart}>
//           Add to Cart
//         </button>
//       </div>
//     </div>
//   );
// };
// export default ProductDetail;
import React, { useEffect, useState, useContext } from "react";
import "./ProductDetail.scss";
import { IoClose } from "react-icons/io5";
import { CartContext } from "../../Context/CartContext";
import { toast } from "react-toastify";

const ProductDetail = ({ product, onClose }) => {
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [variants, setVariants] = useState([]);
  const { dispatch } = useContext(CartContext);

  const [isInitialVariantSet, setIsInitialVariantSet] = useState(false);

  useEffect(() => {
    if (product) {
      setVariants(product.variants || []);
      // setSelectedImage(product.images?.[0] || null);

      if (product.variants?.length > 0 && !isInitialVariantSet) {
        const initialVariant = product.variants[0];
        setSelectedVariant(initialVariant);

        const initialAttributes = {};
        initialVariant.variantAttributes.forEach((attr) => {
          initialAttributes[attr.attribute.name] = attr.attribute_value.name;
        });
        setSelectedAttributes(initialAttributes);

        const variantImage = product.images.find(
          (img) => img.id === initialVariant.imageId
        );
        setSelectedImage(variantImage || product.images[0]);

        setIsInitialVariantSet(true);
      }
    }
  }, [product, isInitialVariantSet]);

  const attributesNames = Array.from(
    new Set(
      variants.flatMap((variant) =>
        variant.variantAttributes.map((attr) => attr.attribute.name)
      )
    )
  );

  const getAttributeValues = (attributeName) => {
    return Array.from(
      new Set(
        variants
          .flatMap((variant) => variant.variantAttributes)
          .filter((attr) => attr.attribute.name === attributeName)
          .map((attr) => attr.attribute_value.name)
      )
    );
  };

  const isValueValid = (attributeName, value) => {
    const baseAttributeName = attributesNames[0];
    if (attributeName === baseAttributeName) {
      return true;
    }

    return variants.some((variant) => {
      const matchesBaseAttribute = variant.variantAttributes.some(
        (attr) =>
          attr.attribute.name === baseAttributeName &&
          attr.attribute_value.name === selectedAttributes[baseAttributeName]
      );

      const matchesAttribute = variant.variantAttributes.some(
        (attr) =>
          attr.attribute.name === attributeName &&
          attr.attribute_value.name === value
      );

      return matchesBaseAttribute && matchesAttribute;
    });
  };

  // const handleAttributeSelect = (attributeName, value) => {
  //   console.log(attributeName, value, "attribute selected");
  // const matchingVariant = variants.find((variant) =>
  //   variant.variantAttributes.some(
  //     (attr) =>
  //       attr.attribute.name === attributeName &&
  //       attr.attribute_value.name === value
  //   )
  // );
  // if (matchingVariant) {
  //   const newSelectedAttributes = {};
  //   console.log(matchingVariant, "matchingvariant");
  //   matchingVariant.variantAttributes.forEach((attr) => {
  //     newSelectedAttributes[attr.attribute.name] = attr.attribute_value.name;
  //   });
  //   setSelectedVariant(matchingVariant);
  //   setSelectedAttributes(newSelectedAttributes);
  //   if (matchingVariant.imageId) {
  //     const variantImage = product.images.find(
  //       (img) => img.id === matchingVariant.imageId
  //     );
  //     setSelectedImage(variantImage || product.images[0]);
  //   }
  // }
  // };
  const handleAttributeSelect = (attributeName, value) => {
    const updatedAttributes = {
      ...selectedAttributes,
      [attributeName]: value,
    };

    const matchingVariant = variants.find((variant) =>
      variant.variantAttributes.every(
        (attr) =>
          updatedAttributes[attr.attribute.name] === attr.attribute_value.name
      )
    );

    if (matchingVariant) {
      setSelectedVariant(matchingVariant);
      setSelectedAttributes(updatedAttributes);

      if (matchingVariant.imageId) {
        const variantImage = product.images.find(
          (img) => img.id === matchingVariant.imageId
        );
        setSelectedImage(variantImage || product.images[0]);
      }
    } else {
      const matchingVariant = variants.find((variant) =>
        variant.variantAttributes.some(
          (attr) =>
            attr.attribute.name === attributeName &&
            attr.attribute_value.name === value
        )
      );
      if (matchingVariant) {
        const newSelectedAttributes = {};
        console.log(matchingVariant, "matchingvariant");
        matchingVariant.variantAttributes.forEach((attr) => {
          newSelectedAttributes[attr.attribute.name] =
            attr.attribute_value.name;
        });
        setSelectedVariant(matchingVariant);
        setSelectedAttributes(newSelectedAttributes);
        if (matchingVariant.imageId) {
          const variantImage = product.images.find(
            (img) => img.id === matchingVariant.imageId
          );
          setSelectedImage(variantImage || product.images[0]);
        }
      }
      console.warn("No matching variant found for the selected attributes");
    }
  };

  const handleAddToCart = () => {
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        productDetails: product,
        variant: selectedVariant,
        quantity,
      },
    });
    onClose();
    console.log("productsssssss", product);
  };

  return (
    <div className="product-detail-overlay" onClick={onClose}>
      <div className="product-detail" onClick={(e) => e.stopPropagation()}>
        <button className="close-btn" onClick={onClose}>
          <IoClose />
        </button>
        {selectedImage && (
          <img
            src={selectedImage.imageUrl}
            alt={product.name}
            className="modal-product-image"
          />
        )}
        <h3>{product.name}</h3>
        <div className="options">
          {attributesNames.map((attributeName) => (
            <div key={attributeName} className="attribute-values">
              <p>
                <span>{`${attributeName}:`}</span>
              </p>
              <div className="size">
                {getAttributeValues(attributeName).map((value) => {
                  const isDisabled = !isValueValid(attributeName, value);
                  return (
                    <button
                      key={value}
                      onClick={() =>
                        !isDisabled &&
                        handleAttributeSelect(attributeName, value)
                      }
                      className={`button ${
                        selectedAttributes[attributeName] === value
                          ? "active"
                          : isDisabled
                          ? "disabled"
                          : ""
                      }`}
                      aria-pressed={selectedAttributes[attributeName] === value}
                      disabled={isDisabled}
                    >
                      {value}
                    </button>
                  );
                })}
              </div>
            </div>
          ))}
          <div className="attribute-values">
            <p>Price:</p>
            <div className="size">
              <p>₹{selectedVariant?.salePrice || product.salePrice}</p>
            </div>
          </div>
        </div>
        <button className="add-to-cart-btn" onClick={handleAddToCart}>
          Add to Cart
        </button>
      </div>
    </div>
  );
};

export default ProductDetail;
