// import React from "react";
// import "./Cart.scss";
// import Button from "../button/Button";
// import { useNavigate } from "react-router-dom"; // Import useNavigate
// import { IoClose } from "react-icons/io5";

// const CartComponent = ({ cartItems, setCartItems }) => {
//   const navigate = useNavigate();

//   // Ensure cartItems is always an array
//   const items = cartItems || []; // Fallback to an empty array if undefined

//   const handleIncrease = (id) => {
//     setCartItems(
//       items.map((item) =>
//         item.id === id ? { ...item, quantity: item.quantity + 1 } : item
//       )
//     );
//   };

//   const handleDecrease = (id) => {
//     setCartItems(
//       items.map((item) =>
//         item.id === id && item.quantity > 1
//           ? { ...item, quantity: item.quantity - 1 }
//           : item
//       )
//     );
//   };

//   const handleRemove = (id) => {
//     setCartItems(items.filter((item) => item.id !== id));
//   };

//   const calculateTotalPrice = () => {
//     return items.reduce((total, item) => total + item.price * item.quantity, 0);
//   };

//   const handleCheckout = () => {
//     navigate("/checkout"); // Navigate to checkout page
//   };

//   return (
//     <div className="cart-container">
//       <h2 className="cart-heading"> Cart</h2>

//       {items.length === 0 ? (
//         <p>Your cart is empty</p>
//       ) : (
//         <div className="cart-items">
//           {items.map((item) => (
//             <div key={item.id} className="cart-item">
//               <div className="cart-item-image">
//                 <img src={item.image} alt={item.title} />
//               </div>
//               <div className="cart-item-details">
//                 <div className="cart-item-title">
//                   <h2>{item.title}</h2>
//                 </div>
//                 <div className="cart-item-brand">
//                   <h3>Brand: {item.brand}</h3>
//                 </div>
//                 <div className="cart-item-color">
//                   <h3>Color: {item.color}</h3>
//                 </div>
//                 <div className="cart-item-detail">
//                   <div className="cart-item-quantity">
//                     <button onClick={() => handleDecrease(item.id)}>-</button>
//                     <span>{item.quantity}</span>
//                     <button onClick={() => handleIncrease(item.id)}>+</button>
//                   </div>
//                   <div className="cart-item-price">
//                     <h3> ₹{item.price * item.quantity}</h3>
//                   </div>
//                   <div className="cart-item-remove">
//                     <button type="submit" onClick={() => handleRemove(item.id)}>
//                       <IoClose />
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       )}

//       <div className="cart-total">
//         <div className="amount">
//           <div>
//             <span>
//               <h1>Total</h1>
//               <h3>
//                 (Items: {items.length}, Quantity:{" "}
//                 {items.reduce((acc, item) => acc + item.quantity, 0)})
//               </h3>
//             </span>
//           </div>
//           <div>
//             <h1>₹{calculateTotalPrice()}</h1>
//           </div>
//         </div>

//         <div className="checkout-button-container">
//           <Button
//             type="submit"
//             label="Proceed To Checkout"
//             className="btn-primary"
//             onClick={handleCheckout}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// CartComponent.defaultProps = {
//   cartItems: [], // Provide an empty array as default
// };

// export default CartComponent;
import React, { useContext, useState } from "react";
import "./Cart.scss";
import Button from "../button/Button";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { CartContext } from "../../Context/CartContext";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { FaMinus, FaPlus } from "react-icons/fa";

const CartComponent = ({ onClose }) => {
  const navigate = useNavigate();

  const { cartItems, dispatch, couponResponse } = useContext(CartContext);

  const handleUpdateQuantity = (productId, variant, newQuantity, stock) => {
    console.log(newQuantity, stock);
    if (newQuantity > stock) {
      toast.error("The quantity exceeds available stock.");
      return;
    }
    if (newQuantity < 1) {
      toast.error("Quantity cannot be less than 1.");
      return;
    }
    dispatch({
      type: "UPDATE_QUANTITY",
      payload: {
        id: productId,
        variant,
        quantity: newQuantity,
      },
    });
  };

  // const getAvailableStock = (item) => {
  //   return item.variant?.stock || item.stock || 0;
  // };
  // const withTaxsubtotalPrice = cartItems.reduce(
  //   (acc, item) =>
  //     acc + (item.variant?.salePrice || item.price) * item.quantity,
  //   0
  // );

  const handleRemoveFromCart = (productId, variant) => {
    dispatch({
      type: "REMOVE_FROM_CART",
      payload: { id: productId, variant },
    });
    toast.success("product Removed sucessfully");
  };

  const calculateTotalPrice = () => {
    return cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };
  const handleCheckout = () => {
    navigate("/checkout"); // Navigate to checkout page
  };
  console.log("cartItems", cartItems);
  return (
    <>
      {" "}
      <div className="heading">
        <h4> Cart</h4>
      </div>
      <div className="cart-container">
        <button className="close-btn" onClick={onClose}>
          <IoClose />
        </button>

        {cartItems.length === 0 ? (
          <div className="empty-cart">
            <img src="../Group.png"></img>
            <p>Your cart is empty</p>
            <p>
              Looks like you have not added anything to your cart. Go ahead &
              explore top categories.{" "}
            </p>
          </div>
        ) : (
          <div className="cart-items">
            {cartItems.map((item) => (
              <>
                <div className="cart-item">
                  <div className="cart-item-image">
                    {/* { const variantImage = response.data.images.find( (img) =>
                  img.id === selectedVariant.imageId );} */}
                    <img
                      src={
                        item.images.find(
                          (img) => img.id === item.variant?.imageId
                        )?.imageUrl || item.images[0].imageUrl
                      }
                      alt={item.title}
                    />
                  </div>
                  <div className="cart-item-details">
                    <div className="details">
                      <div className="detail-content">
                        <div className="cart-item-title">
                          <p>{item.title}</p>
                        </div>
                        <div className="cart-item-brand">
                          <p>Brand: {item.brand.name}</p>
                        </div>
                        <div className="cart-item-color">
                          {item.variant?.variantAttributes?.map((attr) => (
                            <p key={attr.id}>
                              {attr.attribute.name} :{" "}
                              {attr.attribute_value.name}
                            </p>
                          ))}
                        </div>
                      </div>

                      <div className="cart-item-remove">
                        <button
                          type="submit"
                          onClick={() =>
                            handleRemoveFromCart(item.id, item.variant)
                          }
                        >
                          <IoClose />
                        </button>
                      </div>
                    </div>
                    <div className="cart-item-detail">
                      <div className="cart-item-quantity">
                        <button
                          className="decrement"
                          onClick={() =>
                            handleUpdateQuantity(
                              item.id,
                              item.variant,
                              item.quantity - 1
                              // getAvailableStock(item)
                            )
                          }
                          disabled={item.quantity <= 1}
                        >
                          <FaMinus />
                        </button>
                        <p className="quantity-display">{item.quantity}</p>
                        <button
                          className="increment"
                          onClick={() =>
                            handleUpdateQuantity(
                              item.id,
                              item.variant,
                              item.quantity + 1,
                              item.stock
                              // getAvailableStock(item)
                            )
                          }
                        >
                          <FaPlus />
                        </button>
                      </div>
                      <div className="cart-item-price">
                        <p>
                          {" "}
                          ₹
                          {item.variant
                            ? item.variant?.salePrice || 0
                            : item.salePrice || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        )}
      </div>
      <div className="cart-total">
        <div className="amount">
          <div>
            <span>
              <h3>SubTotal</h3>

              <p>
                (Items: {cartItems.length}, Quantity:{" "}
                {cartItems.reduce((acc, item) => acc + item.quantity, 0)})
              </p>
            </span>
          </div>
          <div className="total-price">
            <div className="price">
              <p>₹{calculateTotalPrice().toFixed(2)}</p>
            </div>
          </div>
        </div>

        <div className="checkout-button-container">
          <Button
            type="submit"
            label="Proceed To Checkout"
            className={`btn-primary ${
              cartItems.length === 0 ? "btn-disabled" : ""
            }`}
            onClick={handleCheckout}
            disabled={cartItems.length === 0}
          />
        </div>
      </div>
    </>
  );
};

export default CartComponent;
