// import React, { createContext, useState, useEffect } from "react";
// import axios from "axios";
// import { API_BASE_URL } from "../services/services";

// export const AuthContext = createContext();

// const AuthProvider = ({ children }) => {
//   const BASE_URL = API_BASE_URL;
//   const [isLoggedIn, setIsLoggedIn] = useState(null);
//   const [userDetails, setUserDetails] = useState(null);
//   const [accessToken, setAccessToken] = useState(null);

//   useEffect(() => {
//     const tokenFromCookies = getCookie("accessToken");
//     const userDetailsFromCookies = getCookie("userDetails");

//     if (tokenFromCookies) {
//       setIsLoggedIn(tokenFromCookies);
//     }

//     if (userDetailsFromCookies) {
//       try {
//         setUserDetails(JSON.parse(userDetailsFromCookies));
//       } catch (error) {
//         console.error("Failed to parse user details from cookies:", error);
//       }
//     }
//   }, []);

//   const login = async (email, password) => {
//     try {
//       const response = await axios.post(`${BASE_URL}/auth/login`, {
//         email,
//         password,
//       });
//       const { accessToken, refreshToken, user } = response.data;

//       document.cookie = `accessToken=${accessToken}; path=/`;
//       document.cookie = `refreshToken=${refreshToken}; path=/; `;
//       document.cookie = `userDetails=${JSON.stringify(user)}; path=/; `;

//       sessionStorage.setItem("accessToken", accessToken);
//       sessionStorage.setItem("refreshToken", refreshToken);
//       sessionStorage.setItem("userDetails", JSON.stringify(user));

//       setIsLoggedIn(accessToken);
//       setUserDetails(user);
//       return { success: true };
//     } catch (error) {
//       console.error(
//         "Login failed:",
//         error.response?.data?.error || error.message
//       );
//       return {
//         success: false,
//         message: error.response?.data?.error || "Login failed",
//       };
//     }
//   };

//   const logout = async () => {
//     // If there's a backend logout endpoint, you can call it here
//     // try {
//     //   await axios.post(`${BASE_URL}/api/auth/logout`);
//     // } catch (error) {
//     //   console.error("Logout failed:", error);
//     // }

//     setIsLoggedIn(null);
//     setUserDetails(null);

//     // Remove cookies
//     document.cookie =
//       "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
//     document.cookie =
//       "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
//     document.cookie =
//       "userDetails=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";

//     // Remove from sessionStorage
//     sessionStorage.removeItem("accessToken");
//     sessionStorage.removeItem("refreshToken");
//     sessionStorage.removeItem("userDetails");
//   };

//   const register = async (
//     firstName,
//     lastName,
//     email,
//     phoneNumber,
//     password
//   ) => {
//     try {
//       const response = await axios.post(`${BASE_URL}/api/users`, {
//         firstName,
//         lastName,
//         email,
//         phoneNumber,
//         password,
//       });

//       const { accessToken, refreshToken, user } = response.data;

//       document.cookie = `accessToken=${accessToken};path=/`;
//       document.cookie = `refreshToken=${refreshToken};path=/`;
//       document.cookie = `userDetails=${JSON.stringify(user)}; path=/`;

//       sessionStorage.setItem("accessToken", accessToken);
//       sessionStorage.setItem("refreshToken", refreshToken);
//       sessionStorage.setItem("userDetails", JSON.stringify(user));

//       setIsLoggedIn(accessToken);
//       setUserDetails(user);
//       return { success: true };
//     } catch (error) {
//       console.error("Registration failed:", error.response);
//       return {
//         success: false,
//         message: error.response?.data?.error || "Registration failed",
//       };
//     }
//   };

//   const getCookie = (name) => {
//     const value = `; ${document.cookie}`;
//     const parts = value.split(`; ${name}=`);
//     if (parts.length === 2) return parts.pop().split(";").shift();
//     return null; // Return null if the cookie doesn't exist
//   };

//   return (
//     <AuthContext.Provider value={{ isLoggedIn, login, logout, register }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export default AuthProvider;
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../services/services";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const BASE_URL = API_BASE_URL;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const tokenFromCookies = getCookie("accessToken");
    const userDetailsFromCookies = getCookie("userDetails");

    if (tokenFromCookies) {
      setIsLoggedIn(true);
      setAccessToken(tokenFromCookies);
    }

    if (userDetailsFromCookies) {
      try {
        setUserDetails(JSON.parse(userDetailsFromCookies));
      } catch (error) {
        console.error("Failed to parse user details from cookies:", error);
      }
    }
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${BASE_URL}/auth/login`, {
        email,
        password,
      });
      const { accessToken, refreshToken, user } = response.data;

      // Set cookies with a 1-day expiry (you can adjust the expiry as needed)
      document.cookie = `accessToken=${accessToken}; path=/; max-age=86400`;
      document.cookie = `refreshToken=${refreshToken}; path=/; max-age=86400`;
      document.cookie = `userDetails=${JSON.stringify(
        user
      )}; path=/; max-age=86400`;

      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken);
      sessionStorage.setItem("userDetails", JSON.stringify(user));

      setIsLoggedIn(true);
      setUserDetails(user);
      setAccessToken(accessToken);
      return { success: true };
    } catch (error) {
      console.error(
        "Login failed:",
        error.response?.data?.error || error.message
      );
      return {
        success: false,
        message: error.response?.data?.error || "Login failed",
      };
    }
  };

  const logout = async () => {
    // If there's a backend logout endpoint, you can call it here
    // try {
    //   await axios.post(`${BASE_URL}/api/auth/logout`);
    // } catch (error) {
    //   console.error("Logout failed:", error);
    // }

    setIsLoggedIn(false);
    setUserDetails(null);

    // Remove cookies
    document.cookie =
      "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    document.cookie =
      "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    document.cookie =
      "userDetails=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";

    // Remove from sessionStorage
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("userDetails");
  };

  const register = async (
    firstName,
    lastName,
    email,
    phoneNumber,
    password
  ) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/users`, {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
      });

      const { accessToken, refreshToken, user } = response.data;

      document.cookie = `accessToken=${accessToken};path=/; max-age=86400`;
      document.cookie = `refreshToken=${refreshToken};path=/; max-age=86400`;
      document.cookie = `userDetails=${JSON.stringify(
        user
      )}; path=/; max-age=86400`;

      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken);
      sessionStorage.setItem("userDetails", JSON.stringify(user));

      setIsLoggedIn(true);
      setUserDetails(user);
      setAccessToken(accessToken);
      return { success: true };
    } catch (error) {
      console.error("Registration failed:", error.response);
      return {
        success: false,
        message: error.response?.data?.error || "Registration failed",
      };
    }
  };

  // Get cookie function
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null; // Return null if the cookie doesn't exist
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, login, logout, register, getCookie }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
