import React, { useEffect, useState } from "react";
import { FaCashRegister, FaGooglePay, FaCreditCard } from "react-icons/fa"; // Importing icons
import { paymentTypeCount } from "../../services/services";

function PaymentCounts({ cod, credit, upi }) {
  // console.log(data, "payments");
  const [paymentcount, setPaymentcount] = useState({});

  return (
    <>
      <div className="payment-section">
        {/* Cash Payments */}
        <div className="payment-card">
          <div className="icon-container">
            <img src="/cash.png" alt="cash image" />
          </div>
          <div className="text-container">
            <span className="label">Cash</span>
            <span className="count">₹ {cod?.toFixed(2)}</span>
          </div>
        </div>

        {/* GPay Payments */}
        <div className="payment-card">
          <div className="icon-container">
            <img src="/gpay.png" alt="cash image" />
          </div>
          <div className="text-container">
            <span className="label">Upi</span>
            <span className="count">₹ {upi?.toFixed(2)}</span>
          </div>
        </div>

        {/* Credit/Debit Payments */}
        <div className="payment-card">
          <div className="icon-container">
            <img src="/credit.png" alt="cash image" />
          </div>
          <div className="text-container">
            <span className="label">Credit</span>
            <span className="count">₹ {credit?.toFixed(2)}</span>
          </div>
        </div>
      </div>

      <div className="responsive-paymentcard">
        <div className="payment-card">
          <div className="icon-container">
            <img src="/cash.png" alt="cash image" />
          </div>
          <div className="text-container">
            <span className="count">₹ {cod?.toFixed(2)}</span>
          </div>
        </div>

        <div className="payment-card">
          <div className="icon-container">
            <img src="/gpay.png" alt="cash image" />
          </div>
          <div className="text-container">
            <span className="count">₹ {upi?.toFixed(2)}</span>
          </div>
        </div>

        {/* Credit/Debit Payments */}
        <div className="payment-card">
          <div className="icon-container">
            <img src="/credit.png" alt="cash image" />
          </div>
          <div className="text-container">
            <span className="count">₹ {credit?.toFixed(2)}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentCounts;
