// import React, { useState, useEffect, useCallback, useContext } from "react";
// import "./Product.scss";
// import Searchbar from "../Searchbar/Searchbar";
// import ProductDetail from "./ProductDetail";
// import { IoClose } from "react-icons/io5";
// import { fetchAllProducts } from "../../services/services";
// import InfiniteScroll from "react-infinite-scroll-component";
// import { searchProducts } from "../../services/services";
// import { CartContext } from "../../Context/CartContext";

// const ProductTestComponent = (selectedCategories, selectedBrands) => {
//   const [showFilters, setShowFilters] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState(null); // Store the selected product for the modal
//   const [currentPage, setCurrentPage] = useState(1); // Track current page
//   const [isLoading, setIsLoading] = useState(false); // Track loading state
//   const [hasMore, setHasMore] = useState(true);
//   const [product, setProduct] = useState([]);
//   const [totalProducts, setTotalProducts] = useState([]);
//   const { dispatch } = useContext(CartContext);

//   const [searchQuery, setSearchQuery] = useState("");

//   const fetchData = useCallback(async () => {
//     // Prevent API calls if we're already loading or if there are no more products to fetch
//     if (isLoading || !hasMore) return;

//     setIsLoading(true); // Mark loading state to prevent multiple requests
//     try {
//       const sortOrder = "asc"; // Example sort order
//       const sortBy = "title"; // Example sort key

//       // Make API call to fetch products
//       const fetchedProducts = await fetchAllProducts(
//         currentPage,
//         sortOrder,
//         sortBy
//       );

//       // If no products are returned, stop infinite scroll
//       if (fetchedProducts.products.length === 0) {
//         setHasMore(false); // No more products to fetch
//       } else {
//         // Append new products to the existing list
//         setProduct((prevProducts) => [
//           ...prevProducts,
//           ...fetchedProducts.products,
//         ]);
//         setCurrentPage((prevPage) => prevPage + 1); // Increment the page number for the next API call
//       }
//     } catch (error) {
//       console.error("Error fetching products:", error);
//     } finally {
//       setIsLoading(false); // Reset loading state when done
//     }
//   }, [currentPage, isLoading, hasMore]); // D

//   useEffect(() => {
//     fetchData(); // Initial fetch on component mount
//   }, []); // Empty dependency array to call once when the component mounts

//   const handleProductClick = (product) => {
//     if (product.variants && product.variants.length > 0) {
//       setSelectedProduct(product);
//     } else {
//       // addToCart && addToCart(product, 1);
//       dispatch({
//         type: "ADD_TO_CART",
//         payload: {
//           productDetails: product,
//           // variant: selectedVariant,
//           quantity: 1,
//         },
//       });
//     }
//   };

//   const handleCloseModal = () => {
//     setSelectedProduct(null); // Close the modal
//   };
//   const handleSearch = async (query) => {
//     console.log("Search query:", query);
//     setSearchQuery(query);
//     setProduct([]);
//     setHasMore(true);
//     setCurrentPage(1);

//     if (query) {
//       try {
//         const limit = 10;
//         const searchResults = await searchProducts(query, 1, limit);
//         console.log("searchResults", searchResults);
//         setProduct(searchResults.products || []);
//         setHasMore(searchResults.products.length > 0);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//       }
//     } else {
//       fetchData();
//     }
//   };
//   const handleSearchScroll = async () => {
//     console.log("searchhhhhhhhhhhh", searchQuery);
//     if (searchQuery && hasMore && !isLoading) {
//       setIsLoading(true);
//       try {
//         const limit = 10;
//         const searchResults = await searchProducts(
//           searchQuery,
//           currentPage,
//           limit
//         );
//         setProduct((prevProducts) => [
//           ...prevProducts,
//           ...(searchResults.products || []),
//         ]);
//         setHasMore(searchResults.products.length > 0);
//         setCurrentPage((prevPage) => prevPage + 1);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     }
//   };
//   // useEffect(() => {
//   //   const filteredProducts = product.filter(
//   //     (product) =>
//   //       (selectedCategories.length === 0 ||
//   //         selectedCategories.includes(product.category)) &&
//   //       (selectedBrands.length === 0 || selectedBrands.includes(product.brand))
//   //   );
//   //   setProduct(filteredProducts);
//   // }, [selectedCategories, selectedBrands, product]);

//   return (
//     <>
//       <div className="product" id="Product">
//         <div className="search-bar">
//           <Searchbar onSearch={handleSearch} />
//         </div>
//         <div className="product-container">
//           <button
//             className="filter-link"
//             onClick={(e) => {
//               e.preventDefault();
//               setShowFilters(!showFilters); // Toggle the filters
//             }}
//           >
//             {showFilters ? "Cancel" : "Advanced Filters"}
//           </button>

//           {showFilters && (
//             <div className="filters">
//               <label>
//                 <button className="Filter-btn">
//                   <h3>Preethi</h3>
//                   <h3>
//                     <IoClose />
//                   </h3>
//                 </button>
//               </label>
//               <label>
//                 <button className="Filter-btn">
//                   <h3>Toaster</h3>
//                   <h3>
//                     <IoClose />
//                   </h3>
//                 </button>
//               </label>
//             </div>
//           )}

//           <div className="product-grid">
//             {product.map((product) => (
//               <div
//                 key={product.id}
//                 className="product-card"
//                 onClick={() => handleProductClick(product)} // Trigger modal on product click
//               >
//                 <img src={product.images[0].imageUrl} alt={product.name} />
//               </div>
//             ))}
//           </div>
//         </div>

//         <InfiniteScroll
//           dataLength={product.length} //This is important field to render the next data
//           next={searchQuery ? handleSearchScroll : fetchData}
//           hasMore={hasMore}
//           loader={<h4>Loading...</h4>}
//           scrollableTarget="Product"
//         >
//           {/* {items} */}
//         </InfiniteScroll>
//         {/* Show Product Detail if selectedProduct is not null */}
//         {selectedProduct && (
//           <ProductDetail product={selectedProduct} onClose={handleCloseModal} />
//         )}
//       </div>
//     </>
//   );
// };

// export default ProductTestComponent;

// import React, { useState, useEffect, useCallback } from "react";
// import "./Product.scss";
// import Searchbar from "../Searchbar/Searchbar";
// import ProductDetail from "./ProductDetail";
// import { IoClose } from "react-icons/io5";
// import { fetchAllProducts } from "../../services/services";
// import InfiniteScroll from "react-infinite-scroll-component";
// import { searchProducts } from "../../services/services";
// const ProductTestComponent = ({ selectedCategories }) => {
//   const [showFilters, setShowFilters] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState(null); // Store the selected product for the modal
//   const [currentPage, setCurrentPage] = useState(1); // Track current page
//   const [isLoading, setIsLoading] = useState(false); // Track loading state
//   const [hasMore, setHasMore] = useState(true);
//   const [product, setProduct] = useState([]);
//   const [totalProducts, setTotalProducts] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const fetchData = useCallback(async () => {
//     console.log("selectedCategories", hasMore);

//     if (isLoading || !hasMore) return;
//     setIsLoading(true);
//     try {
//       const sortOrder = "asc";
//       const sortBy = "title";

//       const fetchedProducts = await fetchAllProducts(
//         currentPage,
//         sortOrder,
//         sortBy,
//         selectedCategories
//       );
//       console.log(fetchedProducts, "a");
//       if (fetchedProducts.products.length === 0) {
//         console.log(fetchedProducts.product.length, "yyy");

//         setHasMore(false);
//       } else {
//         setProduct((prevProducts) => [
//           ...prevProducts,
//           ...fetchedProducts.products,
//         ]);
//         setCurrentPage((prevPage) => prevPage + 1);
//       }
//     } catch (error) {
//       console.error("Error fetching products:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   }, [currentPage, isLoading, hasMore]);
//   useEffect(() => {
//     fetchData();
//   }, []);

//   useEffect(() => {
//     setProduct([]);
//     setCurrentPage(1);
//     console.log("ho", currentPage);
//     fetchData();
//   }, [selectedCategories]);

//   const handleProductClick = (product) => {
//     console.log("adasdsd", product);
//     setSelectedProduct(product);
//   };

//   const handleCloseModal = () => {
//     setSelectedProduct(null);
//   };
//   const handleSearch = async (query) => {
//     console.log("Search query:", query);
//     setSearchQuery(query);
//     setProduct([]);
//     setHasMore(true);
//     setCurrentPage(1);

//     if (query) {
//       try {
//         const limit = 10;
//         const searchResults = await searchProducts(query, 1, limit);
//         console.log("searchResults", searchResults);
//         setProduct(searchResults.products || []);
//         setHasMore(searchResults.products.length > 0);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//       }
//     } else {
//       fetchData();
//     }
//   };
//   const handleSearchScroll = async () => {
//     console.log("searchhhhhhhhhhhh", searchQuery);
//     if (searchQuery && hasMore && !isLoading) {
//       setIsLoading(true);
//       try {
//         const limit = 10;
//         const searchResults = await searchProducts(
//           searchQuery,
//           currentPage,
//           limit
//         );
//         setProduct((prevProducts) => [
//           ...prevProducts,
//           ...(searchResults.products || []),
//         ]);
//         setHasMore(searchResults.products.length > 0);
//         setCurrentPage((prevPage) => prevPage + 1);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     }
//   };

//   return (
//     <>
//       <div className="product" id="Product">
//         <div className="search-bar">
//           <Searchbar onSearch={handleSearch} />
//         </div>
//         <div className="product-container">
//           <button
//             className="filter-link"
//             onClick={(e) => {
//               e.preventDefault();
//               setShowFilters(!showFilters); // Toggle the filters
//             }}
//           >
//             {showFilters ? "Cancel" : "Advanced Filters"}
//           </button>

//           {showFilters && (
//             <div className="filters">
//               <label>
//                 <button className="Filter-btn">
//                   <h3>Preethi</h3>
//                   <h3>
//                     <IoClose />
//                   </h3>
//                 </button>
//               </label>
//               <label>
//                 <button className="Filter-btn">
//                   <h3>Toaster</h3>
//                   <h3>
//                     <IoClose />
//                   </h3>
//                 </button>
//               </label>
//             </div>
//           )}

//           <div className="product-grid">
//             {product.map((product) => (
//               <div
//                 key={product.id}
//                 className="product-card"
//                 onClick={() => handleProductClick(product)} // Trigger modal on product click
//               >
//                 <img src={product.images[0].imageUrl} alt={product.name} />
//               </div>
//             ))}
//           </div>
//         </div>

//         <InfiniteScroll
//           dataLength={product.length} //This is important field to render the next data
//           next={searchQuery ? handleSearchScroll : fetchData}
//           hasMore={hasMore}
//           loader={<h4>Loading...</h4>}
//           scrollableTarget="Product"
//         >
//           {/* {items} */}
//         </InfiniteScroll>
//         {/* Show Product Detail if selectedProduct is not null */}
//         {selectedProduct && (
//           <ProductDetail product={selectedProduct} onClose={handleCloseModal} />
//         )}
//       </div>
//     </>
//   );
// };

// export default ProductTestComponent;

// import React, { useState, useEffect, useCallback, useContext } from "react";
// import "./Product.scss";
// import Searchbar from "../Searchbar/Searchbar";
// import ProductDetail from "./ProductDetail";
// import { IoClose } from "react-icons/io5";
// import { fetchAllProducts } from "../../services/services";
// import InfiniteScroll from "react-infinite-scroll-component";
// import { searchProducts } from "../../services/services";
// import { CartContext } from "../../Context/CartContext";
// const ProductTestComponent = ({ selectedCategories }) => {
//   const [showFilters, setShowFilters] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState(null); // Store the selected product for the modal
//   const [currentPage, setCurrentPage] = useState(1); // Track current page
//   const [isLoading, setIsLoading] = useState(false); // Track loading state
//   const [hasMore, setHasMore] = useState(true);
//   const [product, setProduct] = useState([]);
//   const [totalProducts, setTotalProducts] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const { dispatch } = useContext(CartContext);

//   const fetchData = useCallback(async () => {
//     console.log("selectedCategories", isLoading, hasMore);
//     if (isLoading || !hasMore) return;
//     setIsLoading(true);
//     try {
//       const sortOrder = "asc";
//       const sortBy = "title";
//       const fetchedProducts = await fetchAllProducts(
//         currentPage,
//         sortOrder,
//         sortBy,
//         selectedCategories
//       );
//       console.log(fetchedProducts, "a");
//       if (fetchedProducts.products.length === 0) {
//         console.log(fetchedProducts.product.length, "yyy");
//         setHasMore(false);
//       } else {
//         setProduct((prevProducts) => [
//           ...prevProducts,
//           ...fetchedProducts.products,
//         ]);
//         setCurrentPage((prevPage) => prevPage + 1);
//       }
//     } catch (error) {
//       console.error("Error fetching products:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   }, [currentPage, isLoading, hasMore]);
//   useEffect(() => {
//     console.log("before", currentPage);
//     fetchData();
//     console.log("a", currentPage);
//   }, []);
//   useEffect(() => {
//     setProduct([]);

//     setCurrentPage(1);
//     fetchData();
//   }, [selectedCategories.length]);
// const handleProductClick = (product) => {
//   if (product.variants && product.variants.length > 0) {
//     setSelectedProduct(product);
//   } else {
//     dispatch({
//       type: "ADD_TO_CART",
//       payload: {
//         productDetails: product,
//         // variant: selectedVariant,
//         quantity: 1,
//       },
//     });
//   }
// };
//   const handleCloseModal = () => {
//     setSelectedProduct(null);
//   };
//   const handleSearch = async (query) => {
//     console.log("Search query:", query);
//     setSearchQuery(query);
//     setProduct([]);
//     setHasMore(true);
//     setCurrentPage(1);
//     if (query) {
//       try {
//         const limit = 10;
//         const searchResults = await searchProducts(query, 1, limit);
//         console.log("searchResults", searchResults);
//         setProduct(searchResults.products || []);
//         setHasMore(searchResults.products.length > 0);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//       }
//     } else {
//       fetchData();
//     }
//   };
//   const handleSearchScroll = async () => {
//     console.log("searchhhhhhhhhhhh", searchQuery);
//     if (searchQuery && hasMore && !isLoading) {
//       setIsLoading(true);
//       try {
//         const limit = 10;
//         const searchResults = await searchProducts(
//           searchQuery,
//           currentPage,
//           limit
//         );
//         setProduct((prevProducts) => [
//           ...prevProducts,
//           ...(searchResults.products || []),
//         ]);
//         setHasMore(searchResults.products.length > 0);
//         setCurrentPage((prevPage) => prevPage + 1);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     }
//   };
//   return (
//     <>
//       <div className="product" id="Product">
//         <div className="search-bar">
//           <Searchbar onSearch={handleSearch} />
//         </div>
//         <div className="product-container">
//           <button
//             className="filter-link"
//             onClick={(e) => {
//               e.preventDefault();
//               setShowFilters(!showFilters); // Toggle the filters
//             }}
//           >
//             {showFilters ? "Cancel" : "Advanced Filters"}
//           </button>
//           {showFilters && (
//             <div className="filters">
//               <label>
//                 <button className="Filter-btn">
//                   <h3>Preethi</h3>
//                   <h3>
//                     <IoClose />
//                   </h3>
//                 </button>
//               </label>
//               <label>
//                 <button className="Filter-btn">
//                   <h3>Toaster</h3>
//                   <h3>
//                     <IoClose />
//                   </h3>
//                 </button>
//               </label>
//             </div>
//           )}
//           <div className="product-grid">
//             {product.map((product) => (
//               <div
//                 key={product.id}
//                 className="product-card"
//                 onClick={() => handleProductClick(product)} // Trigger modal on product click
//               >
//                 <img src={product.images[0].imageUrl} alt={product.name} />
//               </div>
//             ))}
//           </div>
//         </div>
//         <InfiniteScroll
//           dataLength={product.length} //This is important field to render the next data
//           next={fetchData}
//           hasMore={hasMore}
//           loader={<h4>Loading...</h4>}
//           scrollableTarget="Product"
//         >
//           {/* {items} */}
//         </InfiniteScroll>
//         {/* Show Product Detail if selectedProduct is not null */}
//         {selectedProduct && (
//           <ProductDetail product={selectedProduct} onClose={handleCloseModal} />
//         )}
//       </div>
//     </>
//   );
// };
// export default ProductTestComponent;

// import React, { useState, useEffect, useCallback, useContext } from "react";
// import "./Product.scss";
// import Searchbar from "../Searchbar/Searchbar";
// import ProductDetail from "./ProductDetail";
// import { IoClose } from "react-icons/io5";
// import { fetchAllProducts, searchProductTest } from "../../services/services";
// import InfiniteScroll from "react-infinite-scroll-component";
// import { searchProducts } from "../../services/services";
// import { CartContext } from "../../Context/CartContext";
// const ProductTestComponent = ({ selectedCategories, selectedBrands }) => {
//   const [showFilters, setShowFilters] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState(null); // Store the selected product for the modal
//   const [currentPage, setCurrentPage] = useState(1); // Track current page
//   const [isLoading, setIsLoading] = useState(false); // Track loading state
//   const [hasMore, setHasMore] = useState(true);
//   const [product, setProduct] = useState([]);
//   const [totalProducts, setTotalProducts] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const { dispatch } = useContext(CartContext);
//   const fetchData = useCallback(async () => {
//     // console.log("selectedCategories", isLoading, hasMore);

//     if (isLoading || !hasMore) return;
//     setIsLoading(true);
//     try {
//       let fetchedProducts;
//       if (searchQuery) {
//         // handleSearch(searchQuery);
//         const sortOrder = "asc";
//         const sortBy = "title";
//         const limit = 30;

//         // Clear previous products while fetching new results
//         setProduct([]);

//         const fetchedProducts = await searchProductTest(
//           currentPage,
//           sortOrder,
//           sortBy,
//           selectedCategories,
//           selectedBrands,
//           searchQuery
//         );

//         // if (searchResults && searchResults.products) {
//         //   console.log("Fetched products:", searchResults.products.length);
//         //   setProduct(searchResults.products); // Replace the product state
//         //   setHasMore(searchResults.products.length > 0);
//         // }
//       } else {
//         const sortOrder = "asc";
//         const sortBy = "title";

//         fetchedProducts = await fetchAllProducts(
//           currentPage,
//           sortOrder,
//           sortBy,
//           selectedCategories,
//           selectedBrands
//         );
//       }
//       // const fetchedProducts = await fetchAllProducts(
//       //   currentPage,
//       //   sortOrder,
//       //   sortBy,
//       //   selectedCategories,
//       //   selectedBrands
//       // );
//       console.log(fetchedProducts, "fetchedproduct cat");
//       if (fetchedProducts.products.length === 0) {
//         // console.log(fetchedProducts.product.length, "yyy");

//         setHasMore(false);
//       } else {
//         setProduct((prevProducts) => [
//           ...prevProducts,
//           ...fetchedProducts.products,
//         ]);

//         setCurrentPage((prevPage) => prevPage + 1);
//       }

//       console.log("setproduct", product);
//       console.log("currentpage", currentPage);
//     } catch (error) {
//       console.error("Error fetching products:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   }, [currentPage, isLoading, hasMore, selectedCategories, selectedBrands]);

//   // useEffect(() => {
//   //   console.log("before", currentPage);

//   //   fetchData();

//   //   console.log("a", currentPage);
//   // }, []);

//   // useEffect(() => {
//   //   setProduct([]);
//   //   console.log(selectedBrands, "product brands");
//   //   console.log(selectedCategories, "product categories");
//   //   console.log(currentPage, "currentPagebefore");
//   //   setCurrentPage(1);
//   //   console.log(currentPage, "currentPageafter");
//   //   fetchData();
//   // }, [selectedCategories, selectedBrands]);
//   useEffect(() => {
//     setProduct([]);
//     setCurrentPage(1);
//     setHasMore(true);
//   }, [selectedCategories, selectedBrands]);

//   useEffect(() => {
//     fetchData(); // Fetch data whenever currentPage updates
//   }, [currentPage, fetchData]); // Dependency array includes fetchData and currentPage

//   const handleProductClick = (product) => {
//     if (product.variants && product.variants.length > 0) {
//       setSelectedProduct(product);
//     } else {
//       dispatch({
//         type: "ADD_TO_CART",
//         payload: {
//           productDetails: product,
//           // variant: selectedVariant,
//           quantity: 1,
//         },
//       });
//     }
//   };
//   const handleCloseModal = () => {
//     setSelectedProduct(null);
//   };
//   const handleSearch = async (query) => {
//     console.log("Search query:", query);

//     setSearchQuery(query); // Update the search query state
//     setHasMore(true);
//     setCurrentPage(1);

//     if (query) {
//       try {
//         const limit = 30;

//         // Clear previous products while fetching new results
//         setProduct([]);

//         const searchResults = await searchProducts(query, 1, limit);

//         if (searchResults && searchResults.products) {
//           console.log("Fetched products:", searchResults.products.length);
//           setProduct(searchResults.products); // Replace the product state
//           setHasMore(searchResults.products.length > 0);
//         } else {
//           console.warn("No products found for the query:", query);
//           setProduct([]);
//           setHasMore(false);
//         }
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//       }
//     } else {
//       fetchData(); // Fetch initial data when query is empty
//     }
//   };

//   const handleSearchScroll = async () => {
//     // console.log("searchhhhhhhhhhhh", searchQuery);
//     if (searchQuery && hasMore && !isLoading) {
//       setIsLoading(true);
//       try {
//         const limit = 10;
//         const searchResults = await searchProducts(
//           searchQuery,
//           currentPage,
//           limit
//         );
//         setProduct((prevProducts) => [
//           ...prevProducts,
//           ...(searchResults.products || []),
//         ]);
//         setHasMore(searchResults.products.length > 0);
//         setCurrentPage((prevPage) => prevPage + 1);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     }
//   };

//   return (
//     <>
//       <div className="product" id="Product">
//         <div className="search-bar">
//           <Searchbar onSearch={handleSearch} />
//         </div>
//         <div className="product-container">
//           <button
//             className="filter-link"
//             onClick={(e) => {
//               e.preventDefault();
//               setShowFilters(!showFilters); // Toggle the filters
//             }}
//           >
//             {showFilters ? "Cancel" : "Advanced Filters"}
//           </button>

//           {showFilters && (
//             <div className="filters">
//               <label>
//                 <button className="Filter-btn">
//                   <h3>Preethi</h3>
//                   <h3>
//                     <IoClose />
//                   </h3>
//                 </button>
//               </label>
//               <label>
//                 <button className="Filter-btn">
//                   <h3>Toaster</h3>
//                   <h3>
//                     <IoClose />
//                   </h3>
//                 </button>
//               </label>
//             </div>
//           )}

//           <div className="product-grid">
//             {product.map((product) => (
//               <div
//                 key={product.id}
//                 className="product-card"
//                 onClick={() => handleProductClick(product)} // Trigger modal on product click
//               >
//                 <img src={product.images[0].imageUrl} alt={product.name} />
//               </div>
//             ))}
//           </div>
//         </div>

//         <InfiniteScroll
//           dataLength={product.length} //This is important field to render the next data
//           next={fetchData}
//           hasMore={hasMore}
//           loader={<h4>Loading...</h4>}
//           scrollableTarget="Product"
//         >
//           {/* {items} */}
//         </InfiniteScroll>
//         {/* Show Product Detail if selectedProduct is not null */}
//         {selectedProduct && (
//           <ProductDetail product={selectedProduct} onClose={handleCloseModal} />
//         )}
//       </div>
//     </>
//   );
// };

// export default ProductTestComponent;
import React, { useState, useEffect, useCallback, useContext } from "react";
import "./Product.scss";
import Searchbar from "../Searchbar/Searchbar";
import ProductDetail from "./ProductDetail";
import { IoClose } from "react-icons/io5";
import {
  fetchAllProducts,
  searchProducts,
  searchProductTest,
} from "../../services/services";
import InfiniteScroll from "react-infinite-scroll-component";
import { CartContext } from "../../Context/CartContext";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify"; //this library use for toastmessage
import "react-toastify/dist/ReactToastify.css";
import { FaSearch } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProductTestComponent = ({ selectedCategories, selectedBrands }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [product, setProduct] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { dispatch } = useContext(CartContext);

  useEffect(() => {
    console.log("hasMore updated:", hasMore);
  }, [hasMore]);
  useEffect(() => {
    console.log("CurrentPage updated:", currentPage);
  }, [currentPage]);
  const handleScroll = () => {
    if (hasMore && !isLoading) {
      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        fetchData(nextPage); // Fetch the next page
        return nextPage;
      });
    }
  };

  const MyImage = ({ image }) => (
    <LazyLoadImage
      alt={image.alt}
      effect="blur"
      wrapperProps={{
        // If you need to, you can tweak the effect transition using the wrapper style.
        style: { transitionDelay: "1s" },
      }}
      src={image.src}
    />
  );
  // Fetch data from the API
  let current = 1;
  const fetchData = useCallback(
    async (page, query) => {
      const url = new URL(window.location.href); // Get the current URL
      let searchQuery = url.searchParams.get("");
      searchQuery = url.searchParams.get("search");
      console.log(searchQuery, "queryyyyyyyyyyyyyyyyyyy");
      // let fetchedProducts;
      if (isLoading) return; // Prevent redundant calls

      setIsLoading(true);
      try {
        const sortOrder = "asc";
        const sortBy = "title";
        const limit = 30;
        if (searchQuery) {
          const fetchedProducts = await searchProductTest(
            1,
            "ASC",
            "createdAt",
            selectedCategories,
            selectedBrands,
            searchQuery
          );
          console.log("heloooooooooooooooo");
          if (fetchedProducts.products.length === 0) {
            setHasMore(false); // No more products to fetch
          } else {
            setProduct((prevProducts) => [
              ...prevProducts,
              ...fetchedProducts.products,
            ]);
            setCurrentPage((prevPage) => prevPage + 1); // Increment page for next fetch
          }
        } else {
          const fetchedProducts = await fetchAllProducts(
            page ? page : currentPage,
            sortOrder,
            sortBy,
            selectedCategories,
            selectedBrands
          );
          console.log("heloooooooooooooooo");
          if (fetchedProducts.products.length === 0) {
            setHasMore(false); // No more products to fetch
          } else {
            setProduct((prevProducts) => [
              ...prevProducts,
              ...fetchedProducts.products,
            ]);
            setCurrentPage((prevPage) => prevPage + 1); // Increment page for next fetch
          }
        }
        // if (fetchedProducts.products.length === 0) {
        //   setHasMore(false); // No more products to fetch
        // } else {
        //   setProduct((prevProducts) => [
        //     ...prevProducts,
        //     ...fetchedProducts.products,
        //   ]);
        //   setCurrentPage((prevPage) => prevPage + 1); // Increment page for next fetch
        // }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, hasMore, selectedCategories, selectedBrands]
  );
  // useEffect(() => {
  //   setCurrentPage(1);
  // }, [selectedCategories, selectedBrands]);
  const resetData = async () => {
    setCurrentPage(1);
    setProduct([]);
    setHasMore(true);
    console.log("Resetting data due to category/brand selection");
  };
  useEffect(() => {
    const fetchDataWithReset = async () => {
      await resetData();
    };
    fetchDataWithReset();
    console.log(currentPage, "currentpage test");
    setHasMore(true);

    fetchData(1);
  }, [selectedCategories, selectedBrands, searchQuery]);
  // // Fetch data whenever the page changes
  // useEffect(() => {
  //   fetchData();
  // }, [currentPage, fetchData]);
  // Handle product click (modal or add to cart)
  const handleProductClick = (product) => {
    if (product.variants && product.variants.length > 0) {
      console.log(product, "orosssds");

      setSelectedProduct(product);
    } else {
      console.log(product, "orosssds");
      if (product.stock == 0) {
        toast.error("Stock Not Available");
        return;
      }
      dispatch({
        type: "ADD_TO_CART",
        payload: {
          productDetails: product,
          quantity: 1,
        },
      });
      handleCloseModal();
      // toast.success("Product Added to Cart Successfully");
    }
  };
  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  const handleSearchScroll = async () => {
    console.log("EHEHJEj");
    if (searchQuery && hasMore && !isLoading) {
      setIsLoading(true);
      try {
        const limit = 30;
        const searchResults = await searchProducts(
          searchQuery,
          currentPage,
          limit
        );
        setProduct((prevProducts) => [
          ...prevProducts,
          ...(searchResults.products || []),
        ]);
        setHasMore(searchResults.products.length > 0);
        setCurrentPage((prevPage) => prevPage + 1);
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const url = new URL(window.location);
    if (query) {
      url.searchParams.set("search", query); // Add or update the 'search' query parameter
    } else {
      url.searchParams.delete("search"); // Remove 'search' if query is empty
    }
    window.history.pushState({}, "", url); //
  };
  const handleSearchReset = () => {
    setSearchQuery("");
    fetchData();
    console.log("Search reset to onload state.");
  };
  console.log("searchQuery", searchQuery);
  return (
    <>
      <div className="search-bar">
        {/* <Searchbar
            onChange={(e) => {
              setSearchQuery(e.target.value);

              console.log(searchQuery, "searchquery");
            }}
            onReset={handleSearchReset}
          /> */}
        <input
          class="search-container"
          type="text"
          value={searchQuery}
          // onChange={(e) => setSearchQuery(e.target.value)}
          onChange={handleInputChange}
          placeholder="Search"
        />
        <span class="search-icon">
          <FaSearch />
        </span>
        <div>
          {/* <button
            className="filter-link"
            onClick={(e) => {
              e.preventDefault();
              setShowFilters(!showFilters); // Toggle filters
            }}
          >
            {showFilters ? "Cancel" : "Advanced Filters"}
          </button>
          {showFilters && (
            <div className="filters">
              <label>
                <button className="Filter-btn">
                  <h3>Preethi</h3>
                  <h3>
                    <IoClose />
                  </h3>
                </button>
              </label>
              <label>
                <button className="Filter-btn">
                  <h3>Toaster</h3>
                  <h3>
                    <IoClose />
                  </h3>
                </button>
              </label>
            </div>
          )} */}
        </div>
      </div>
      <div className="product" id="Product">
        <div className="product-container">
          <div className="product-grid">
            {product.length == 0 && !hasMore ? (
              <>
                {console.log(product, "product")}

                <div className="empty-state">
                  <img src="./empty.png" alt="No product available" />
                  <p>Product not found!</p>
                </div>
              </>
            ) : (
              product.map((item) => (
                <div
                  key={item?.id}
                  className="product-card"
                  onClick={() => handleProductClick(item)}
                >
                  <img
                    src={item?.images[0]?.imageUrl}
                    alt={item?.name || "Product"}
                  />
                </div>
              ))
            )}
          </div>
        </div>

        {isLoading && (
          <div className="loader-container">
            <ClipLoader
              color="blue"
              loading={isLoading}
              size={60}
              speedMultiplier={0.5}
            />
          </div>
        )}
        {console.log(isLoading, "isloading")}

        <InfiniteScroll
          dataLength={product.length}
          next={fetchData}
          hasMore={hasMore}
          scrollableTarget="Product"
        >
          {/* Infinite Scroll will trigger next() for loading more data */}
        </InfiniteScroll>
        {selectedProduct && (
          <ProductDetail product={selectedProduct} onClose={handleCloseModal} />
        )}
      </div>
    </>
  );
};
export default ProductTestComponent;
