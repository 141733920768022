// import React, { useState } from "react";
// import "./Product.scss";
// import Searchbar from "../Searchbar/Searchbar";

// const ProductComponent = () => {
//   const [showFilters, setShowFilters] = useState(false);

//   const products = [
//     { id: 1, name: "Product 1", image: "/cooker.png" },
//     { id: 2, name: "Product 2", image: "/grinder.png" },
//     { id: 3, name: "Product 3", image: "/mixie.png" },
//     { id: 1, name: "Product 1", image: "/cooker.png" },
//     { id: 2, name: "Product 2", image: "/grinder.png" },
//     { id: 3, name: "Product 3", image: "/mixie.png" },
//     { id: 1, name: "Product 1", image: "/cooker.png" },
//     { id: 2, name: "Product 2", image: "/grinder.png" },
//     { id: 3, name: "Product 3", image: "/mixie.png" },
//     { id: 1, name: "Product 1", image: "/cooker.png" },
//     { id: 2, name: "Product 2", image: "/grinder.png" },
//     { id: 3, name: "Product 3", image: "/mixie.png" },
//     { id: 1, name: "Product 1", image: "/cooker.png" },
//     { id: 2, name: "Product 2", image: "/grinder.png" },
//     { id: 3, name: "Product 3", image: "/mixie.png" },
//     { id: 1, name: "Product 1", image: "/cooker.png" },
//     { id: 2, name: "Product 2", image: "/grinder.png" },
//     { id: 3, name: "Product 3", image: "/mixie.png" },
//     { id: 1, name: "Product 1", image: "/cooker.png" },
//     { id: 2, name: "Product 2", image: "/grinder.png" },
//     { id: 3, name: "Product 3", image: "/mixie.png" },
//     { id: 1, name: "Product 1", image: "/cooker.png" },
//     { id: 2, name: "Product 2", image: "/grinder.png" },
//     { id: 3, name: "Product 3", image: "/mixie.png" },
//     { id: 1, name: "Product 1", image: "/cooker.png" },
//     { id: 2, name: "Product 2", image: "/grinder.png" },
//     { id: 3, name: "Product 3", image: "/mixie.png" },
//     { id: 1, name: "Product 1", image: "/cooker.png" },
//     { id: 2, name: "Product 2", image: "/grinder.png" },
//     { id: 3, name: "Product 3", image: "/mixie.png" },
//     { id: 1, name: "Product 1", image: "/cooker.png" },
//     { id: 2, name: "Product 2", image: "/grinder.png" },
//     { id: 3, name: "Product 3", image: "/mixie.png" },
//   ];

//   return (
//     <>
//       {" "}
//       <div className="product">
//         <div className="search-bar">
//           <Searchbar />
//         </div>
//         <div className="product-container">
//           <a
//             href="#"
//             className="filter-link"
//             onClick={(e) => {
//               e.preventDefault();
//               setShowFilters(!showFilters);
//             }}
//           >
//             {showFilters ? "Hide Filters" : "Advanced Filters"}
//           </a>

//           {showFilters && (
//             <div className="filters">
//               <label>
//                 <input type="checkbox" /> Filter by price
//               </label>
//               <label>
//                 <input type="checkbox" /> Filter by category
//               </label>
//             </div>
//           )}

//           <div className="product-grid">
//             {products.map((product) => (
//               <div key={product.id} className="product-card">
//                 <img src={product.image} alt={product.name} />
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ProductComponent;
// import React, { useState, useEffect, useCallback } from "react";
// import "./Product.scss";
// import Searchbar from "../Searchbar/Searchbar";
// import ProductDetail from "./ProductDetail";
// import { IoClose } from "react-icons/io5";
// import { fetchAllProducts } from "../../services/services";

// const ProductComponent = () => {
//   const [showFilters, setShowFilters] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [isLoading, setIsLoading] = useState(false);
//   const [hasMore, setHasMore] = useState(true);
//   const [product, setProduct] = useState([]);

//   const handleProductClick = (product) => {
//     setSelectedProduct(product);
//   };

//   const handleCloseModal = () => {
//     setSelectedProduct(null);
//   };
//   const loadProducts = useCallback(async () => {
//     if (isLoading || !hasMore) return;
//     setIsLoading(true);

//     try {
//       const sortOrder = "asc";
//       const sortBy = "title";
//       const fetchedProducts = await fetchAllProducts(
//         currentPage,
//         sortOrder,
//         sortBy
//       );

//       if (fetchedProducts.length > 0) {
//         setProduct((prevProducts) => [...prevProducts, ...fetchedProducts]);
//         setCurrentPage((prevPage) => prevPage + 1); // Increment the page for the next request
//       } else {
//         setHasMore(false); // No more products available
//       }
//     } catch (error) {
//       console.error("Error fetching products:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   }, [currentPage, isLoading, hasMore]);
//   useEffect(() => {
//     loadProducts(); // Load products on component mount
//   }, [loadProducts]);
//   const handleScroll = () => {
//     const scrollHeight = document.documentElement.scrollHeight;
//     const scrollTop = document.documentElement.scrollTop;
//     const clientHeight = document.documentElement.clientHeight;

//     // If the user is near the bottom of the page, load more products
//     if (scrollHeight - scrollTop <= clientHeight + 200) {
//       loadProducts();
//     }
//   };
//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll); // Clean up event listener on unmount
//   }, [loadProducts]);

//   return (
//     <>
//       <div className="product">
//         <div className="search-bar">
//           <Searchbar onSearch={handleSearch} />
//         </div>
//         <div className="product-container">
//           <button
//             className="filter-link"
//             onClick={(e) => {
//               e.preventDefault();
//               setShowFilters(!showFilters); // Toggle the filters
//             }}
//           >
//             {showFilters ? "Cancel" : "Advanced Filters"}
//           </button>

//           {showFilters && (
//             <div className="filters">
//               <label>
//                 <button className="Filter-btn">
//                   <h3>Preethi</h3>
//                   <h3>
//                     <IoClose />
//                   </h3>
//                 </button>
//               </label>
//               <label>
//                 <button className="Filter-btn">
//                   <h3>Toaster</h3>
//                   <h3>
//                     <IoClose />
//                   </h3>
//                 </button>
//               </label>
//             </div>
//           )}

//           <div className="product-grid">
//             {product.map((product) => (
//               <div
//                 key={product.id}
//                 className="product-card"
//                 onClick={() => handleProductClick(product)} // Trigger modal on product click
//               >
//                 <img src={product.images[0].imageUrl} alt={product.name} />
//               </div>
//             ))}
//           </div>
//         </div>

//         {/* Show Product Detail if selectedProduct is not null */}
//         {selectedProduct && (
//           <ProductDetail product={selectedProduct} onClose={handleCloseModal} />
//         )}
//       </div>
//     </>
//   );
// };

// export default ProductComponent;

// import "./Product.scss";
// import Searchbar from "../Searchbar/Searchbar";
// import ProductDetail from "./ProductDetail";
// import { IoClose } from "react-icons/io5";
// import { fetchAllProducts } from "../../services/services";
// import { useState, useEffect, useCallback } from "react";

// const ProductComponent = () => {
//   const [showFilters, setShowFilters] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [isLoading, setIsLoading] = useState(false);
//   const [hasMore, setHasMore] = useState(true);
//   const [product, setProduct] = useState([]);

//   const handleProductClick = (product) => {
//     setSelectedProduct(product);
//   };

//   const handleCloseModal = () => {
//     setSelectedProduct(null);
//   };

//   const loadProducts = useCallback(async () => {
//     if (isLoading || !hasMore) return;
//     setIsLoading(true);

//     try {
//       const sortOrder = "asc";
//       const sortBy = "title";
//       const fetchedProducts = await fetchAllProducts(
//         currentPage,
//         sortOrder,
//         sortBy
//       );

//       if (fetchedProducts.length > 0) {
//         setProduct((prevProducts) => [...prevProducts, ...fetchedProducts]);
//         setCurrentPage((prevPage) => prevPage + 1);
//       } else {
//         setHasMore(false);
//       }
//     } catch (error) {
//       console.error("Error fetching products:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   }, [currentPage, isLoading, hasMore]);

//   useEffect(() => {
//     loadProducts();
//   }, [loadProducts]);

//   const handleScroll = () => {
//     const scrollHeight = document.documentElement.scrollHeight;
//     const scrollTop = document.documentElement.scrollTop;
//     const clientHeight = document.documentElement.clientHeight;

//     if (scrollHeight - scrollTop <= clientHeight + 200) {
//       loadProducts();
//     }
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, [loadProducts]);

//   const handleSearch = (query) => {
//     if (!query) {
//       setCurrentPage(1);
//       setHasMore(true);
//       setProduct([]);
//       console.log("after opage" , currentPage)
//       loadProducts();
//       return;
//     }

//     const filteredProducts = product.filter((prod) =>
//       prod.name.toLowerCase().includes(query.toLowerCase())
//     );

//     setProduct(filteredProducts);

//     setHasMore(false);
//   };

//   return (
//     <>
//       <div className="product">
//         <div className="search-bar">
//           <Searchbar onSearch={handleSearch} />
//         </div>
//         <div className="product-container">
//           <button
//             className="filter-link"
//             onClick={(e) => {
//               e.preventDefault();
//               setShowFilters(!showFilters);
//             }}
//           >
//             {showFilters ? "Cancel" : "Advanced Filters"}
//           </button>

//           {showFilters && (
//             <div className="filters">
//               <label>
//                 <button className="Filter-btn">
//                   <h3>Preethi</h3>
//                   <h3>
//                     <IoClose />
//                   </h3>
//                 </button>
//               </label>
//               <label>
//                 <button className="Filter-btn">
//                   <h3>Toaster</h3>
//                   <h3>
//                     <IoClose />
//                   </h3>
//                 </button>
//               </label>
//             </div>
//           )}

//           <div className="product-grid">
//             {product.map((product) => (
//               <div
//                 key={product.id}
//                 className="product-card"
//                 onClick={() => handleProductClick(product)}
//               >
//                 <img src={product.images[0].imageUrl} alt={product.name} />
//               </div>
//             ))}
//           </div>
//         </div>

//         {selectedProduct && (
//           <ProductDetail product={selectedProduct} onClose={handleCloseModal} />
//         )}
//       </div>
//     </>
//   );
// };

// export default ProductComponent;

import React, { useEffect, useState, useContext } from "react";
import "./ProductDetail.scss";
import { IoClose } from "react-icons/io5";
import { CartContext } from "../../Context/CartContext";
import "./Product.scss";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
const ProductDetails = ({ product, onClose, addToCart }) => {
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const [variants, setVariants] = useState([]);
  const { dispatch } = useContext(CartContext);
  const [imageId, setImageId] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  return (
    <div>
      {isLoading && (
        <div className="loader-container">
          <ClipLoader
            color="red"
            loading={isLoading}
            size={350}
            speedMultiplier={0.5}
          />
        </div>
      )}
    </div>
  );
};
export default ProductDetails;
