import React, { useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import "./signup.scss";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Button from "../../components/button/Button";
import { toast } from "react-toastify";

const SignUp = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { isLoggedIn, login, register } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setLoginError("");
    try {
      const result = await login(values.email, values.password);
      if (result.success) {
        resetForm();
        navigate("/main-page");
        toast.success("Login successfully");
        window.location.reload();
      } else {
        setLoginError(result.message);
      }
    } catch (error) {
      setLoginError("An error occurred during login.");
    }
    setSubmitting(false);
  };
  return (
    <div className="signup">
      <div className="signup-container">
        <div className="signin-img">
          <img class="part1" src="/muthu-logo.png" alt="Login" />
          <img class="part2" src="/muthu-logo-text.png" alt="Login" />
        </div>

        <div className="signin-form">
          <h2 className="signup-heading">Welcome Back!</h2>
          <div className="login-form-signup">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  {loginError && <div className="error-msg">{loginError}</div>}
                  <div className="form-wrapper">
                    <label htmlFor="email">
                      <span>Username</span>{" "}
                    </label>
                    <Field type="email" id="email" name="email" />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />

                    <label htmlFor="password">
                      <span>Password</span>
                      <div
                        className="password-container"
                        style={{ position: "relative" }}
                      >
                        <Field
                          type={passwordVisible ? "text" : "password"}
                          id="password"
                          name="password"
                          className="password-input"
                        />

                        <i
                          onClick={() => setPasswordVisible(!passwordVisible)}
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "15px",
                            top: "35%",
                          }}
                        >
                          {passwordVisible ? <FaEyeSlash /> : <FaEye />}{" "}
                        </i>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error"
                      />
                    </label>
                    <div className="remember-me">
                      <label htmlFor="rememberMe">
                        <Field
                          type="checkbox"
                          id="rememberMe"
                          name="rememberMe"
                        />
                        <span>Remember Me</span>
                      </label>

                      <div className="form-footer">
                        <div className="forgot-password">
                          <a href="/forgot-password">Forgot Password?</a>
                        </div>
                      </div>
                    </div>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      label="Log In"
                      className="btn-primary"
                      // onClick={() => navigate("/register")}
                    />
                  </div>
                </Form>
              )}
            </Formik>

            {/* <div className="divider">
              <span className="divider-text">OR</span>
            </div> */}

            {/* <div className="register-link">
              <span>Don't have an account? </span>
              <a href="/register" className="login-anchor">
                Sign up here
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
