// import { FaMinus } from "react-icons/fa";

// get access token from cookies function
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
};

// //nested categories structure converted into array
// export const flattenCategories = (categories) => {
//   const flatCategories = [];
//   const flatten = (category, parentName = <FaMinus />) => {
//     flatCategories.push({
//       ...category,
//       parentName,
//       featuredCategory: category.featuredCategory ? "ON" : "OFF",
//     });
//     if (category.children && category.children.length > 0) {
//       category.children.forEach((child) => flatten(child, category.name));
//     }
//   };
//   categories.forEach((category) => flatten(category));
//   return flatCategories;
// };
