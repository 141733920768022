import React, { useContext, useState, useEffect } from "react";
import "./Profile.scss";
import { FaUser } from "react-icons/fa6";
import { FaSignOutAlt } from "react-icons/fa";
import UpdateProfile from "./UpdateProfile";
import { getCookie } from "../../utils/TokenUtils";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { logout } = useContext(AuthContext);
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    const accessToken = getCookie("accessToken");
    if (accessToken) {
      // navigate("/");
    } else {
      navigate("/sign-in");
    }
  }, [isLoggedIn, navigate]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleLogout = () => {
    logout();
  };
  return (
    <div className="profile">
      <div className="profile-pic">
        <img src="/profile.png" alt="Profile" onClick={toggleSidebar} />
      </div>
      <div className={`sidebar-profile ${isSidebarOpen ? "open" : ""}`}>
        <div className="icon-section">
          <div>
            <FaUser />
          </div>
          <div>
            <a href="/profile">MyProfile</a>
          </div>
        </div>
        <div className="logout-sec">
          <div>
            {" "}
            <FaSignOutAlt />
          </div>
          <div>
            {" "}
            <button className="logout-btn" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
