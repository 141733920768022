import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SearchForm = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onclick,
  searchkey,
  setSearchKey,
  onclicking,
}) => {
  // Set current date if startDate and endDate are initially null
  const currentDate = new Date();

  const handleProductInputChange = (e) => {
    setSearchKey(e.target.value); // Update the searchText state in the parent
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onclick(); // Trigger the search
  };

  const Handleclear = () => {
    setSearchKey("");
    setStartDate(null);
    setEndDate(null);
    onclicking();
  };

  return (
    <div className="search-form">
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="input-group">
            <label htmlFor="products">Order Search</label>
            <input
              type="text"
              className="Product-search"
              id="search"
              value={searchkey}
              onChange={handleProductInputChange}
              placeholder="Search order"
            />
          </div>

          <div className="input-group">
            <label htmlFor="startDate">Start Date</label>
            <DatePicker
              selected={startDate} // Set current date if startDate is null
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select Start Date"
              className="date-picker"
              isClearable={false}
              maxDate={currentDate}
            />
          </div>

          <div className="input-group">
            <label htmlFor="endDate">End Date</label>
            <DatePicker
              selected={endDate} // Set current date if endDate is null
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select End Date"
              className="date-picker"
              isClearable={false}
              minDate={startDate}
              maxDate={currentDate}
            />
          </div>

          <div className="search-btn">
            <div className="report-button">
              {/* Corrected the onClick event for Handleclear */}
              <button type="button" onClick={Handleclear}>
                Clear
              </button>
              <button type="submit" onClick={onclick}>
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchForm;
